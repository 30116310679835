import { BacktestResult } from './../../../tradstrat-api/model/BacktestResult';
import { Component, EventEmitter, Input, NgZone, OnInit, Output, SimpleChanges } from '@angular/core';

// See: https://apexcharts.com/angular-chart-demos/line-charts/syncing-charts/

import { ApexAxisChartSeries, ApexTitleSubtitle, ApexDataLabels, ApexFill, ApexMarkers, ApexYAxis, ApexXAxis, ApexTooltip, ApexStroke, ApexLegend, ApexAnnotations } from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: any;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  title: ApexTitleSubtitle;
  fill: ApexFill;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  grid: any;
  colors: any;
  toolbar: any;
  animation: any;
};

@Component({
  selector: 'app-backtestchart',
  templateUrl: './backtestchart.component.html',
  styleUrls: ['./backtestchart.component.scss']
})
export class BacktestchartComponent implements OnInit {

  @Input() backtestResult: BacktestResult[] = []; // decorate the property with @Input()
  @Output() chartRenderedEvent = new EventEmitter<boolean>();

  chartsInitialized : boolean = false;
  numResults : number;
  decimalPlaces: number = 2;

  // ApexCharts Zoom Information
  xAxisMinimumAllowed : number;
  xAxisMaximumAllowed : number;
  xAxisMinimum : number;
  xAxisMaximum : number;

  // Global ApexCharts Configuration
  legend: ApexLegend = {
    position: 'bottom',
    showForSingleSeries: false,
    horizontalAlign: 'left',
    floating: true,
    offsetX: 48,
    offsetY: -24,
  };
  toolbar = {
    show: true,
    tools: {
      download: true,
      selection: false,
      zoom: false,
      zoomin: true,
      zoomout: true,
      pan: false,
      reset: '<img src="/assets/images/reset.svg" width="20">'
    }
  };
  animations = {
    enabled: false,
    easing: 'easeinout',
    speed: 800,
    animateGradually: {
        enabled: true,
        delay: 150
    },
    dynamicAnimation: {
        enabled: true,
        speed: 350
    }
  }; // See: https://apexcharts.com/docs/options/chart/animations/
  signalAnnotations: ApexAnnotations; // See: https://apexcharts.com/angular-chart-demos/line-charts/line-chart-with-annotations/
  public commonOptions: Partial<ChartOptions> = {
    dataLabels: { enabled: false },
    stroke: { curve: "smooth", width: 2 },
    tooltip: {
      followCursor: false, theme: "light", marker: { show: false }, style: { fontSize: '11px' },
      x: { show: false, format: 'dd.MM.yyyy' },
      y: { title: { formatter: (seriesName) => seriesName } }
    },
    grid: {
      clipMarkers: false,
      padding: { top: 0, right: 0, bottom: 0, left: 0 }
    }
  };


  // Specific ApexCharts Configuration
  public chartOptions: Partial<ChartOptions>;

  constructor(private _ngZone: NgZone) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.chartsInitialized = false;
    this.xAxisMinimumAllowed = null;
    this.xAxisMaximumAllowed = null;
    const zoomEvents = {
      beforeZoom: function(chartContext : any, { xaxis }) {
        if (this.xAxisMinimumAllowed === null) {
          this.xAxisMinimumAllowed = chartContext.minX; // Determine initial zoom
        }
        if (this.xAxisMaximumAllowed === null) {
          this.xAxisMaximumAllowed = chartContext.maxX; // Determine initial zoom
        }
        this.xAxisMaximum = this.xAxisMaximumAllowed
        if (xaxis.min < chartContext.minX) {
          return { xaxis: { min: this.xAxisMinimumAllowed, max: this.xAxisMaximumAllowed } };
        }
        return { xaxis: { min: xaxis.min, max: this.xAxisMaximumAllowed } };
      },
    }
    this.chartOptions = {
      series: [
        { name: "DAX", data: this.generateDaxSeries() },
        { name: "MIDAS", data: this.generateMidasSeries() }
      ],
      chart: { id: "dax-midas", group: "backtest", type: "line", height: 300, animations: this.animations, toolbar: this.toolbar, events: zoomEvents },
      colors: ["#d00000", "#004080"],
      xaxis: { type: "datetime", position: 'bottom', min: this.xAxisMinimum, max: this.xAxisMaximum, labels: { offsetY: -2, format: 'dd.MM.yyyy' } }, // See: https://apexcharts.com/docs/datetime/
      yaxis: { tickAmount: 10, forceNiceScale: true, labels: { offsetX: -10, minWidth: 50 } },
      stroke: { width: [1, 2], curve: ["smooth", "smooth"], dashArray: [0, 0] },
    };
    this.chartsInitialized = true;
    this.chartRenderedEvent.emit(true);
  }

  private generateDaxSeries() : any[] {
    let series = [];
    for (let i = 0 ; i < this.backtestResult.length; i++) {
      if (i === 0) {
        this.determineDecimalPlaces(this.backtestResult[i].underDax);
      }
      series.push([this.backtestResult[i].snapshotTimestamp.valueOf(), this.backtestResult[i].daxResult.toFixed(this.decimalPlaces)]);
    }
    return series;
  }

  private generateMidasSeries() : any[] {
    let series = [];
    for (let i = 0 ; i < this.backtestResult.length; i++) {
      if (i === 0) {
        this.determineDecimalPlaces(this.backtestResult[i].underDax);
      }
      series.push([this.backtestResult[i].snapshotTimestamp.valueOf(), this.backtestResult[i].underDax.toFixed(this.decimalPlaces)]);
    }
    return series;
  }

  private determineDecimalPlaces(startValue: number) : void {
    if (startValue >= 1000) {
      this.decimalPlaces = 2;
    } else if (startValue >= 10) {
      this.decimalPlaces = 3;
    } else {
      this.decimalPlaces = 4;
    }
  }

}
