<div class="d-flex justify-content-center mb-5">
  <img mat-card-image class="img" src="/assets/images/tradstrat.png" width="360px" height="112px">
</div>

<!-- ALERT (Shown, when login error occurred) -->
<div *ngIf="authService.loginError$ | async as error" class="container">
  <ngb-alert type="custom" [dismissible]="false" class="error-custom mt-3">
    <div class="alert-widget"><b>Login failed:</b> {{ error.error }}<br/>{{ error.description }}</div>
  </ngb-alert>

  <div class="login-message">
    <span class="login-link" title="{{ 'LOGIN' | translate }}" (click)="retryLogin()" (focus)="retryLogin()">{{ 'LOGIN_RETRY_MESSAGE' | translate }}</span>
  </div>
  <div class="login-abort-message mt-3">
    <span class="login-link" title="{{ 'HOME' | translate }}" (click)="abortLogin()" (focus)="abortLogin()">{{ 'LOGIN_ABORT_MESSAGE' | translate }}</span>
  </div>

</div>

<div class="container" *ngIf="!errorOccurred">
  <div class="row justify-content-md-center">
    <div class="col-md-auto mt-5" style="text-align: center;">
      {{ 'SPINNER_DEFAULT_MESSAGE' | translate }}<br><br>
      {{ 'SPINNER_REDIRECT_LOGIN' | translate }}
    </div>
  </div>
</div>
