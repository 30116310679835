import { TradeSummaryResult } from './model/TradeSummaryResult';
import { BacktestResult } from './model/BacktestResult';
import { BacktestOptions } from './model/request/BacktestOptions';
import { MidasOptions } from './model/request/MidasOptions';
import { MidasResult } from './model/MidasResult';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';

const postRequestHeaders = new HttpHeaders()
  .set('Accept', 'application/json')
  .set('Content-Type', 'application/json');

const getRequestHeaders = new HttpHeaders()
  .set('Accept', 'application/json');

// tslint:disable: no-string-literal tslint:disable-next-line: max-line-length

@Injectable( { providedIn: 'root' } )
export class TradStratApiService {
  private healthURL = environment.tradStratApiUrl + '/';
  private infoUrl = environment.tradStratApiUrl + '/status/info';
  private snapshotDatabaseUrl = environment.tradStratApiUrl + '/api/rds/snapshots/interval';
  private snapshotDatabaseLatestUrl = environment.tradStratApiUrl + '/api/rds/snapshots/latest';
  private midasUrl = environment.tradStratApiUrl + '/api/midas/multi';
  private midasLatestUrl = environment.tradStratApiUrl + '/api/midas/latest';
  private backtestUrl = environment.tradStratApiUrl + '/api/backtest';
  private tradingURL = environment.tradStratApiUrl + '/api/trade';

  // Create subjects of health ans API version
  health = new BehaviorSubject<boolean>(false);
  apiVersion = new BehaviorSubject<string>('?');

// Create subjects and public observables of snapshot data
  private latestStockSnapshotSubject$ = new BehaviorSubject<any>(null); // TODO
  latestStockSnapshot$ = this.latestStockSnapshotSubject$.asObservable();
  private stockSnapshotsSubject$ = new BehaviorSubject<any[]>(null); // TODO
  stockSnapshots$ = this.stockSnapshotsSubject$.asObservable();

  // Create subjects and public observables of midas data
  private latestMidasResultSubject$ = new BehaviorSubject<MidasResult>(null);
  latestMidasResult$ = this.latestMidasResultSubject$.asObservable();
  private midasResultsSubject$ = new BehaviorSubject<MidasResult[]>(null);
  midasResults$ = this.midasResultsSubject$.asObservable();

  // Create subjects and public observables of backtest data
  private backtestResultsSubject$ = new BehaviorSubject<BacktestResult[]>(null);
  backtestResults$ = this.backtestResultsSubject$.asObservable();

  // Create subjects and public observables of trading data
  private tradeSummarySubject$ = new BehaviorSubject<TradeSummaryResult>(null);
  tradeSummary$ = this.tradeSummarySubject$.asObservable();

  constructor(private http: HttpClient) {
    this.getHealth();
  }

  getHealth(): void {
    this.http.get<any>(this.healthURL, { headers: getRequestHeaders, observe: 'response' }).subscribe((response: HttpResponse<any>) => {
      // console.log(JSON.stringify(response)); // DEBUG ONLY
      if (response.body['status']) {
        this.health.next(response.body['status'] === 'UP' || false);
        console.log('TradStrat server health: ' + this.health.getValue());
      }
      this.getInfo();
    },
    (error: HttpErrorResponse) => {
      console.error('Check health... ERROR', error);
      this.health.next(false);
    });
  }

  getInfo(): void {
    this.http.get<any>(this.infoUrl, { headers: getRequestHeaders, observe: 'response' }).subscribe((response: HttpResponse<any>) => {
      if (response.body['app']['version']) {
        // console.log(JSON.stringify(response)); // DEBUG ONLY
        this.apiVersion.next(response.body['app']['version']);
        console.log('TradStrat API-Version: ' + this.apiVersion.getValue());
        // TODO: Maybe regard further properties from info endpoint
      }
    },
    (error: HttpErrorResponse) => {
      this.apiVersion.next('?');
      console.error('Check API Version... ERROR', error);
    });
  }

  getLatestMidas(midasOptions : MidasOptions): void {
    this.http.post<any>(this.midasLatestUrl, midasOptions, { headers: postRequestHeaders, observe: 'response' }).subscribe((response: any) => {
      // console.log(JSON.stringify(response)); // DEBUG ONLY
      this.latestMidasResultSubject$.next(response.body);
      // console.log(JSON.stringify(this.latestMidasResult$.getValue())); // DEBUG ONLY
    },
    (error: HttpErrorResponse) => {
      this.latestMidasResultSubject$.next(null);
      console.error('Get latest Midas result... ERROR', error);
    });
  }

  getMidas(midasOptions : MidasOptions): void {
    this.http.post<any>(this.midasUrl, midasOptions, { headers: postRequestHeaders, observe: 'response' }).subscribe((response: any) => {
      // console.log(JSON.stringify(response)); // DEBUG ONLY
      this.midasResultsSubject$.next(response.body);
      // console.log(JSON.stringify(this.midasResultsSubject$.getValue())); // DEBUG ONLY
    },
    (error: HttpErrorResponse) => {
      this.midasResultsSubject$.next([]);
      console.error('Get Midas results... ERROR', error);
    });
  }

  getLatestSnapshotFromDatabase(): void {
    this.http.get<any>(this.snapshotDatabaseLatestUrl, { headers: getRequestHeaders, observe: 'response' }).subscribe((response: any) => {
      // console.log(JSON.stringify(response)); // DEBUG ONLY
      this.latestStockSnapshotSubject$.next(response.body);
      // console.log(JSON.stringify(this.latestStockSnapshotSubject$.getValue())); // DEBUG ONLY
    },
    (error: HttpErrorResponse) => {
      this.latestStockSnapshotSubject$.next(null);
      console.error('Get latest Snapshot result from database... ERROR', error);
    });
  }

  getSnapshotsIntervalFromDatabase(minTimestamp : number, maxTimestamp : number): void {
    const getUrl = this.snapshotDatabaseUrl + "?minTimestamp=" + minTimestamp + "&maxTimestamp=" + maxTimestamp;
    this.http.get<any>(getUrl, { headers: getRequestHeaders, observe: 'response' }).subscribe((response: any) => {
      // console.log(JSON.stringify(response)); // DEBUG ONLY
      this.stockSnapshotsSubject$.next(response.body);
      // console.log(JSON.stringify(this.stockSnapshotsSubject$.getValue())); // DEBUG ONLY
    },
    (error: HttpErrorResponse) => {
      this.stockSnapshotsSubject$.next([]);
      console.error('Get Snapshot results from database... ERROR', error);
    });
  }

  getBacktest(backtestOptions: BacktestOptions): void {
    this.http.post<any>(this.backtestUrl, backtestOptions, { headers: postRequestHeaders, observe: 'response' }).subscribe((response: any) => {
      // console.log(JSON.stringify(response)); // DEBUG ONLY
      this.backtestResultsSubject$.next(response.body);
      // console.log(JSON.stringify(this.backtestResultsSubject$.getValue())); // DEBUG ONLY
    },
    (error: HttpErrorResponse) => {
      this.backtestResultsSubject$.next([]);
      console.error('Get Backtest results... ERROR', error);
    });
  }

  getTradingApiSummary(): void {
    const getUrl = this.tradingURL + "/summary";
    this.http.get<any>(getUrl, { headers: getRequestHeaders, observe: 'response' }).subscribe((response: any) => {
      // console.log(JSON.stringify(response)); // DEBUG ONLY
      this.tradeSummarySubject$.next(response.body);
      // console.log(JSON.stringify(this.tradeSummarySubject$.getValue())); // DEBUG ONLY
    },
    (error: HttpErrorResponse) => {
      this.tradeSummarySubject$.next(null);
      console.error('Get Trading API Summary... ERROR', error);
    });
  }

}
