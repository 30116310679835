import { DateRange } from './DateRange';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-daterange',
  templateUrl: './daterange.component.html',
  styleUrls: ['./daterange.component.scss']
})
export class DaterangeComponent implements OnInit {

  @Output() selectedRangeEvent = new EventEmitter<DateRange>();

  // Icons from font awesome
  faCalendar = faCalendarAlt;

  rangeModel: string; // 'YTD', 'YEAR', 'CUSTOM
  // Datepicker
  dateRange: FormGroup;
  // CustomYear
  customYear: number;
  // Selected Range
  selectedFromDate: Date;
  selectedToDate: Date;

  // First date available
  firstDate = new Date(2012, 0, 1, 12, 0, 0, 0);
  actualYear = (new Date()).getUTCFullYear();
  availableYears: number[] = [];

  constructor() {
    // Set initial 'from' and 'to' dates for datepicker

    this.dateRange = new FormGroup({
      start: new FormControl(new Date(this.actualYear, 0, 1)),
      end: new FormControl(new Date()),
    });
    /*
    this.dateRange = new FormGroup({
      start: new FormControl(new Date(2011, 11, 14, 0, 0, 0, 0)),
      end: new FormControl(new Date(2012, 11, 31, 23, 59, 59, 0)),
    });
    */
    // Fill available years array and dropdown
    for (let year = this.firstDate.getUTCFullYear(); year <= this.actualYear; year++) {
      this.availableYears.push(year);
    }

    // Set initial range model
    this.rangeModel = "YTD";
    // this.rangeModel = "CUSTOM";
    // this.rangeModel = "YEAR";

    // Set initial year for years dropdown
    // this.customYear = this.availableYears[0]; // first available year
    this.customYear = this.actualYear; // latest available year

  }

  ngOnInit(): void {
    this.setSelectedDate();
  }

  setSelectedDate() : void {
    this.selectedFromDate = new Date();
    this.selectedFromDate.setUTCHours(0);
    this.selectedFromDate.setUTCMinutes(0);
    this.selectedFromDate.setUTCSeconds(0);
    this.selectedFromDate.setUTCMilliseconds(0);
    this.selectedToDate = new Date();
    this.selectedToDate.setUTCHours(23);
    this.selectedToDate.setUTCMinutes(59);
    this.selectedToDate.setUTCSeconds(59);
    this.selectedToDate.setUTCMilliseconds(999);

    if (this.rangeModel === 'YTD') {
      this.selectedFromDate.setUTCMonth(0);
      this.selectedFromDate.setUTCDate(1);
    } else if (this.rangeModel === 'YEAR') {
      this.selectedFromDate.setFullYear(this.customYear)
      this.selectedFromDate.setUTCMonth(0);
      this.selectedFromDate.setUTCDate(1);
      this.selectedToDate.setFullYear(this.customYear)
      this.selectedToDate.setUTCMonth(11);
      this.selectedToDate.setUTCDate(31);
    } else if (this.rangeModel === 'CUSTOM') {
      let startDate = this.dateRange.value.start as Date;
      let endDate = this.dateRange.value.end as Date;
      try {
        this.selectedFromDate.setUTCFullYear(startDate.getFullYear())
        this.selectedFromDate.setUTCMonth(startDate.getMonth())
        this.selectedFromDate.setUTCDate(startDate.getDate());
      } catch (error) {
        // console.log("Range can not be determined: " + error);
        this.selectedFromDate = null;
      }
      try {
        this.selectedToDate.setUTCFullYear(endDate.getFullYear())
        this.selectedToDate.setUTCMonth(endDate.getMonth())
        this.selectedToDate.setUTCDate(endDate.getDate());
      } catch (error) {
        // console.log("Range can not be determined: " + error);
        this.selectedToDate = null;
      }
    }

    this.selectedRangeEvent.emit(new DateRange(this.selectedFromDate, this.selectedToDate));

  }

}
