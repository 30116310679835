import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';

// tslint:disable: no-string-literal

@Injectable({ providedIn: 'root' })
export class UserPermissionsService {

  // Create subject, public observable and public properties for frontend permissions
  private permissionsCheckCompleteSubject$ = new BehaviorSubject<boolean>(false);
  permissionsCheckComplete$ = this.permissionsCheckCompleteSubject$.asObservable();
  permissionsCheckComplete = false;

  private allowReadStockSubject$ = new BehaviorSubject<boolean>(null); // Role: Default-User
  allowReadStock$ = this.allowReadStockSubject$.asObservable();
  allowReadStock: boolean = null;
  private allowCalculateMidasSubject$ = new BehaviorSubject<boolean>(null); // Role: Default-User
  allowCalculateMidas$ = this.allowCalculateMidasSubject$.asObservable();
  allowCalculateMidas: boolean = null;
  private allowCalculateBacktestSubject$ = new BehaviorSubject<boolean>(null); // Role: Extended-User
  allowCalculateBacktest$ = this.allowCalculateBacktestSubject$.asObservable();
  allowCalculateBacktest: boolean = null;
  private allowReadTradesSubject$ = new BehaviorSubject<boolean>(null); // Role: Extended-User
  allowReadTrades$ = this.allowReadTradesSubject$.asObservable();
  allowReadTrades: boolean = null;
  private allowWriteStockSubject$ = new BehaviorSubject<boolean>(null); // Role: Maintainer
  allowWriteStock$ = this.allowWriteStockSubject$.asObservable();
  allowWriteStock: boolean = null;
  private allowPutTradesSubject$ = new BehaviorSubject<boolean>(null); // Role: Maintainer
  allowPutTrades$ = this.allowPutTradesSubject$.asObservable();
  allowPutTrades: boolean = null;
  private allowCrudDatabaseSubject$ = new BehaviorSubject<boolean>(null); // Role: Admin
  allowCrudDatabase$ = this.allowCrudDatabaseSubject$.asObservable();
  allowCrudDatabase: boolean = null;
  private emailVerifiedSubject$ = new BehaviorSubject<boolean>(null);
  emailVerified$ = this.emailVerifiedSubject$.asObservable();
  emailVerified: boolean = null;

  constructor(public authService: AuthService) {

    this.authService.userProfile$.subscribe((profile: any) => {
      // Get permissions from user profile
      let permissions: string [];
      try {
        permissions = profile[environment.auth.namespace]['permissions'];
      } catch (error) { // User profile not available (yet)
        permissions = [];
      }
      // Set subject, public observable and public properties for frontend permissions


      // Read Stock Snapshot live or from database
      try {
        const permitted: boolean = permissions.includes('read:stock') || false;
        this.allowReadStockSubject$.next(permitted);
        this.allowReadStock = permitted;
      } catch (error) {
        this.allowReadStockSubject$.next(null);
        this.allowReadStock = null;
      }
      // Do Midas calculation
      try {
        const permitted: boolean = permissions.includes('calculate:midas') || false;
        this.allowCalculateMidasSubject$.next(permitted);
        this.allowCalculateMidas = permitted;
      } catch (error) {
        this.allowCalculateMidasSubject$.next(null);
        this.allowCalculateMidas = null;
      }
      // Perform Backtest Simulation
      try {
        const permitted: boolean = permissions.includes('calculate:backtest') || false;
        this.allowCalculateBacktestSubject$.next(permitted);
        this.allowCalculateBacktest = permitted;
      } catch (error) {
        this.allowCalculateBacktestSubject$.next(null);
        this.allowCalculateBacktest = null;
      }
      // Get Trades (read only)
      try {
        const permitted: boolean = permissions.includes('read:trades') || false;
        this.allowReadTradesSubject$.next(permitted);
        this.allowReadTrades = permitted;
      } catch (error) {
        this.allowReadTradesSubject$.next(null);
        this.allowReadTrades = null;
      }
      // Perform full Trading
      try {
        const permitted: boolean = permissions.includes('put:trades') || false;
        this.allowPutTradesSubject$.next(permitted);
        this.allowPutTrades = permitted;
      } catch (error) {
        this.allowPutTradesSubject$.next(null);
        this.allowPutTrades = null;
      }
      // Write Stock Snapshot to database
      try {
        const permitted: boolean = permissions.includes('write:stock') || false;
        this.allowWriteStockSubject$.next(permitted);
        this.allowWriteStock = permitted;
      } catch (error) {
        this.allowWriteStockSubject$.next(null);
        this.allowWriteStock = null;
      }
      // Full Database Access
      try {
        const permitted: boolean = permissions.includes('crud:database') || false;
        this.allowCrudDatabaseSubject$.next(permitted);
        this.allowCrudDatabase = permitted;
      } catch (error) {
        this.allowCrudDatabaseSubject$.next(null);
        this.allowCrudDatabase = null;
      }
      // Check, if email adress is verified
      try {
        const emailVerified = profile['email_verified'] === true;
        this.emailVerifiedSubject$.next(emailVerified);
        this.emailVerified = emailVerified;
      } catch (error) {
        this.emailVerifiedSubject$.next(null);
        this.emailVerified = null;
      }
      // Permissions check complete
      this.permissionsCheckCompleteSubject$.next(true);
      this.permissionsCheckComplete = true;
    });

  }

}
