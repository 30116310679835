// See: https://auth0.com/docs/libraries/auth0-spa-js
// See: https://github.com/auth0/auth0-spa-js
// See: https://auth0.github.io/auth0-spa-js/index.html
// See: https://auth0.com/docs/quickstart/spa/angular2/

// See: https://auth0.com/docs/api-auth/tutorials/silent-authentication
// See: https://auth0.com/docs/tokens/concepts/refresh-token-rotation
// See: https://community.auth0.com/t/cross-site-cookies-with-aut0-for-login/36269
// See: https://auth0.com/docs/sessions/concepts/cookie-attributes

// See: https://manfredsteyer.github.io/angular-oauth2-oidc/docs/additional-documentation/routing-with-the-hashstrategy.html

import { Injectable, Inject } from '@angular/core';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { from, of, Observable, BehaviorSubject, combineLatest, throwError } from 'rxjs';
import { tap, catchError, concatMap, shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { WINDOW } from '../shared/services/window-ref';
import { Auth0ConfigClass } from './auth.config';

// tslint:disable: no-string-literal

@Injectable({ providedIn: 'root' })
export class AuthService {
  // Define observable of Auth0 instance of client
  readonly auth0Client$: Observable<Auth0Client>;

  // Define observables for SDK methods that return promises by default
  readonly isAuthenticated$: Observable<boolean>;
  readonly handleRedirectCallback$: Observable<any>;

  // Create subject and public observable of user profile and login error data
  private userProfileSubject$ = new BehaviorSubject<any>(null);
  userProfile$ = this.userProfileSubject$.asObservable();
  private loginErrorSubject$ = new BehaviorSubject<any>(null);
  loginError$ = this.loginErrorSubject$.asObservable();

  // Create public subject to check, if login is in progress (e.g. for spinner)
  loginInProgress$ = new BehaviorSubject<boolean>(false);

  // Create local properties
  loggedIn: boolean = null;  // Login status
  loggedOut: boolean = null; // Check, if user has just logged out

  constructor(private router: Router, @Inject(WINDOW) private window: Window, private auth0Config: Auth0ConfigClass) {
    // Create an observable of Auth0 instance of client
    this.auth0Client$ = (from(
      createAuth0Client({
        domain: this.auth0Config.domain,
        client_id: this.auth0Config.clientID,
        audience: this.auth0Config.audience,
        scope: 'openid profile email update:users',
        useRefreshTokens: true,
        cacheLocation: 'localstorage',
        redirect_uri: this.auth0Config.callbackUrl
        // prompt: 'login', // prompt: 'none'
        // connection: 'Username-Password-Authentication'
      })
    ) as Observable<Auth0Client>).pipe(
      shareReplay(1), // Every subscription receives the same shared value
      catchError(err => throwError(err))
    );

    // For each Auth0 SDK method, first ensure the client instance is ready
    // concatMap: Using the client instance, call SDK method; SDK returns a promise
    // from: Convert that resulting promise into an observable
    this.isAuthenticated$ = this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.isAuthenticated())),
      tap(res => this.loggedIn = res)
    );
    this.handleRedirectCallback$ = this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
    );

    // On initial load, check authentication state with authorization server
    this.localAuthSetup(); // Set up local auth streams if user is already authenticated
    this.handleAuthCallback(); // Handle redirect from Auth0 login
  }

  // When calling, options can be passed if desired
  // See: https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
  getUser$(options?: any): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser(options))),
      tap(user => {
        this.userProfileSubject$.next(user);
      }),
    );
  }

  /** Observable method to retrieve the access token and make it available (e.g. for use in interceptor) */
  getTokenSilently$(options?: any): Observable<string> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getTokenSilently(options)))
    );
  }

  /** Login method */
  login(redirectPath: string = '/'): void {
    // A desired redirect path can be passed to login method (e.g., from a route guard)
    // Ensure Auth0 client instance exists
    this.loginInProgress$.next(true);
    this.auth0Client$.subscribe((client: Auth0Client) => {
      this.loggedOut = false;
      // Call method to log in
      client.loginWithRedirect({
        redirect_uri: this.auth0Config.callbackUrl,
        appState: { target: redirectPath }
      });
    });
  }

  /** Logout method */
  logout(): void {
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log out
      client.logout({
        client_id: this.auth0Config.clientID,
        returnTo: this.auth0Config.logoutUrl
      });
      sessionStorage.setItem('logout', Date.now().toString()); // No security concerns, only to show/hide 'logged out' massage
    });
  }

  /** Setup local authentication streams. This should only be called on app initialization */
  private localAuthSetup(): void {
    this.loginInProgress$.next(true);
    const checkAuth$ = this.isAuthenticated$.pipe(
      concatMap((loggedIn: boolean) => {
        if (loggedIn) { // If authenticated, get user and set in app
          this.loginInProgress$.next(false);
          return this.getUser$(); // NOTE: you could pass options here, if needed
        }
        // If not authenticated, return stream that emits 'false'
        this.loginInProgress$.next(false);
        return of(loggedIn);
      })
    );
    checkAuth$.subscribe();
  }

  /** Call when app reloads after user logs in with Auth0 */
  private handleAuthCallback(): void {
    this.loginInProgress$.next(true);
    const params = this.parseQueryString();
    if (params['error']) { // login returned an error
      const errMessage = decodeURI(params['error']);
      const errDescription = decodeURI(params['error_description'] || '');
      console.error('Error: Login failed!', 'error:', errMessage, 'description:', errDescription);
      this.loginErrorSubject$.next({error: errMessage, description: errDescription});
    } else if (params['code'] && params['state']) {
      let targetRoute: string; // Path to redirect to after login processsed
      const authComplete$ = this.handleRedirectCallback$.pipe(
        // Have client, now call method to handle auth callback redirect
        tap(cbRes => {
          // Get and set target redirect route from callback results
          targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
        }),
        concatMap(() => {
          // Redirect callback complete; get user and login status
          return combineLatest([this.getUser$(), this.isAuthenticated$]);
        })
      );
      // Subscribe to authentication completion observable. Response will be an array of user and login status
      authComplete$.subscribe(([user, loggedIn]) => {
        this.router.navigate([targetRoute]); // Redirect to target route after callback processing
      });
    } else { // if (location.href !== this.auth0Config.logoutUrl)
      // Fix redirect on reload after Auth0 PKCE authentication
      sessionStorage.setItem('lastRequestedRoute', location.pathname.replace(this.auth0Config.baseUrl, ''));
      this.router.navigateByUrl(this.auth0Config.callbackUrl);
    }
    if (sessionStorage.getItem('logout')) { // No security concerns, only to show/hide 'logged out' massage
      this.loggedOut = true;
      this.loginInProgress$.next(false);
      sessionStorage.removeItem('logout');
    }
  }

  private parseQueryString(): any {
    try {
      const searchString = location.search.substring(1);
      const params = searchString.split('&');
      const result = {};
      for (const param of params) {
        const keyVal = param.split('=');
        result[keyVal[0]] = keyVal[1];
      }
      return result;
    } catch (error) {
      return {};
    }
  }

}
