export class Summary {

  firstDate: Date;
  lastDate: Date;
  startValue: number;
  endValue: number;
  highestValue: number;
  lowestValue: number;
  performanceMin: number;
  performanceMax: number;
  performanceFinal: number;

}
