<!-- FOOTER -->
<footer class="footer">
  <div class="container-fluid">
    <div class="row align-items-center footerline smalltext">
      <div class="col text-nowrap">
        &copy; <a class="footer-link" href="https://midasampel.de">midasampel.de</a> 2020-2022
      </div>
      <div class="col">
        <div class="float-right">
          <span class="text-nowrap">
            {{ 'APP_VERSION' | translate }}{{ getAppVersion() }}</span><span *ngIf="content?.apiVersion" class="text-nowrap">,
            {{ 'API_VERSION' | translate }}{{ content?.apiVersion }}
          </span>
          <span *ngIf="content?.apiHealth !== (undefined || null)" class="text-nowrap ml-1">
            <span [ngClass]="(content.apiHealth) ? 'health-ok-icon' : 'health-down-icon'"></span>
          </span>

        </div>
      </div>
    </div>
  </div>
</footer>
