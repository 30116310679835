import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CommonModule } from '@angular/common';
import { I18nComponent } from './i18n.component';
import { Configuration } from './i18n.config';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  declarations: [I18nComponent],
  exports: [TranslateModule]
})
export class I18nModule {
  constructor(public config: Configuration, translate: TranslateService) {
    const languages: string[] = [];
    this.config.providedLanguages.forEach((language: any) => {
      languages.push(language.id);
    });
    translate.addLangs(languages);
    translate.setDefaultLang(config.defaultLanguage);
    translate.use(config.getUserLanguage());
  }
}

export function translateLoaderFactory(httpClient: HttpClient): any {
  return new TranslateHttpLoader(httpClient);
}
