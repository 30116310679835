import { PresetProvider } from './PresetProvider';
import { Preset } from './Preset';
import { Leverages } from './../../../tradstrat-api/model/request/Leverages';
import { Thresholds } from './../../../tradstrat-api/model/request/Thresholds';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-threshold-leverage',
  templateUrl: './threshold-leverage.component.html',
  styleUrls: ['./threshold-leverage.component.scss']
})
export class ThresholdLeverageComponent implements OnInit {

  @Output() selectedPresetEvent = new EventEmitter<Preset>();

  thresholdsLeverages: FormGroup;
  thresholds: Thresholds;
  leverages: Leverages;
  provider = new PresetProvider();
  selectedPreset: string;

  constructor(public translate: TranslateService) {
    this.initializePreset("PRESET_DEFAULT");
  }

  ngOnInit(): void {
  }

  numericOnly(event: KeyboardEvent): boolean {
    const pattern = /^([0-9.-])$/;
    return pattern.test(event.key);
  }

  changeValues(event: InputEvent): void {
    this.thresholds.signalToDarkGreenMidasThreshold = Number(this.thresholdsLeverages.get("signalToDarkGreenMidasThreshold").value);
    this.thresholds.signalToGreenMidasThreshold = Number(this.thresholdsLeverages.get("signalToGreenMidasThreshold").value);
    this.thresholds.signalToLightGreenMidasThreshold = Number(this.thresholdsLeverages.get("signalToLightGreenMidasThreshold").value);
    this.thresholds.signalToDarkYellowMidasThreshold = Number(this.thresholdsLeverages.get("signalToDarkYellowMidasThreshold").value);
    this.thresholds.signalToYellowMidasThreshold = Number(this.thresholdsLeverages.get("signalToYellowMidasThreshold").value);
    this.thresholds.signalToLightYellowMidasThreshold = Number(this.thresholdsLeverages.get("signalToLightYellowMidasThreshold").value);
    this.thresholds.signalToLightRedMidasThreshold = Number(this.thresholdsLeverages.get("signalToLightRedMidasThreshold").value);
    this.thresholds.signalToRedMidasThreshold = Number(this.thresholdsLeverages.get("signalToRedMidasThreshold").value);
    this.leverages.leverageWhenSignalIsDarkGreen = Number(this.thresholdsLeverages.get("leverageWhenSignalIsDarkGreen").value);
    this.leverages.leverageWhenSignalIsGreen = Number(this.thresholdsLeverages.get("leverageWhenSignalIsGreen").value);
    this.leverages.leverageWhenSignalIsLightGreen = Number(this.thresholdsLeverages.get("leverageWhenSignalIsLightGreen").value);
    this.leverages.leverageWhenSignalIsDarkYellow = Number(this.thresholdsLeverages.get("leverageWhenSignalIsDarkYellow").value);
    this.leverages.leverageWhenSignalIsYellow = Number(this.thresholdsLeverages.get("leverageWhenSignalIsYellow").value);
    this.leverages.leverageWhenSignalIsLightYellow = Number(this.thresholdsLeverages.get("leverageWhenSignalIsLightYellow").value);
    this.leverages.leverageWhenSignalIsLightRed = Number(this.thresholdsLeverages.get("leverageWhenSignalIsLightRed").value);
    this.leverages.leverageWhenSignalIsRed = Number(this.thresholdsLeverages.get("leverageWhenSignalIsRed").value);
    this.leverages.leverageWhenSignalIsDarkRed = Number(this.thresholdsLeverages.get("leverageWhenSignalIsDarkRed").value);
    this.selectedPreset = "PRESET_USER_DEFINED";
    this.selectedPresetEvent.emit(new Preset(this.thresholds, this.leverages, this.selectedPreset));
  }

  changePreset(event: Event): void {
    if ((event.target as HTMLOptionElement).innerText !== this.translate.instant('PRESET_USER_DEFINED')) {
      this.initializePreset(this.selectedPreset); // ignore 'User defined' selection
    }
  }

  initializePreset(presetName: string): void {
    const preset: Preset = PresetProvider.getPreset(presetName);
    this.thresholds = preset.thresholds;
    this.leverages = preset.leverages;
    this.selectedPreset = preset.preset;
    this.initializeFormGroup();
    this.selectedPresetEvent.emit(new Preset(this.thresholds, this.leverages, this.selectedPreset));
  }

  initializeFormGroup(): void {
    const numberPattern: string = "^([+-]?[0-9]+(.[0-9]+)?)$"
    this.thresholdsLeverages = new FormGroup({
      signalToDarkGreenMidasThreshold: new FormControl(this.thresholds.signalToDarkGreenMidasThreshold, [Validators.pattern(numberPattern), Validators.required]),
      signalToGreenMidasThreshold: new FormControl(this.thresholds.signalToGreenMidasThreshold, [Validators.pattern(numberPattern), Validators.required]),
      signalToLightGreenMidasThreshold: new FormControl(this.thresholds.signalToLightGreenMidasThreshold, [Validators.pattern(numberPattern), Validators.required]),
      signalToDarkYellowMidasThreshold: new FormControl(this.thresholds.signalToDarkYellowMidasThreshold, [Validators.pattern(numberPattern), Validators.required]),
      signalToYellowMidasThreshold: new FormControl(this.thresholds.signalToYellowMidasThreshold, [Validators.pattern(numberPattern), Validators.required]),
      signalToLightYellowMidasThreshold: new FormControl(this.thresholds.signalToLightYellowMidasThreshold, [Validators.pattern(numberPattern), Validators.required]),
      signalToLightRedMidasThreshold: new FormControl(this.thresholds.signalToLightRedMidasThreshold, [Validators.pattern(numberPattern), Validators.required]),
      signalToRedMidasThreshold: new FormControl(this.thresholds.signalToRedMidasThreshold, [Validators.pattern(numberPattern), Validators.required]),
      leverageWhenSignalIsDarkGreen: new FormControl(this.leverages.leverageWhenSignalIsDarkGreen, [Validators.pattern(numberPattern), Validators.required]),
      leverageWhenSignalIsGreen: new FormControl(this.leverages.leverageWhenSignalIsGreen, [Validators.pattern(numberPattern), Validators.required]),
      leverageWhenSignalIsLightGreen: new FormControl(this.leverages.leverageWhenSignalIsLightGreen, [Validators.pattern(numberPattern), Validators.required]),
      leverageWhenSignalIsDarkYellow: new FormControl(this.leverages.leverageWhenSignalIsDarkYellow, [Validators.pattern(numberPattern), Validators.required]),
      leverageWhenSignalIsYellow: new FormControl(this.leverages.leverageWhenSignalIsYellow, [Validators.pattern(numberPattern), Validators.required]),
      leverageWhenSignalIsLightYellow: new FormControl(this.leverages.leverageWhenSignalIsLightYellow, [Validators.pattern(numberPattern), Validators.required]),
      leverageWhenSignalIsLightRed: new FormControl(this.leverages.leverageWhenSignalIsLightRed, [Validators.pattern(numberPattern), Validators.required]),
      leverageWhenSignalIsRed: new FormControl(this.leverages.leverageWhenSignalIsRed, [Validators.pattern(numberPattern), Validators.required]),
      leverageWhenSignalIsDarkRed: new FormControl(this.leverages.leverageWhenSignalIsDarkRed, [Validators.pattern(numberPattern), Validators.required])
    });
  }

}
