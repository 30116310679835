import { Component, NgZone, OnInit } from '@angular/core';

// See: https://apexcharts.com/angular-chart-demos/line-charts/syncing-charts/

import { ApexChart, ApexAxisChartSeries, ApexTitleSubtitle, ApexDataLabels, ApexFill, ApexMarkers, ApexYAxis, ApexXAxis, ApexTooltip, ApexStroke, ApexLegend, ApexAnnotations } from "ng-apexcharts";
import * as ApexCharts from "apexcharts";
import { UserPermissionsService } from 'src/app/auth/permissions.service';
import { TradStratApiService } from 'src/app/tradstrat-api/api.service';
import { MidasResult } from 'src/app/tradstrat-api/model/MidasResult';
import { MidasOptions } from 'src/app/tradstrat-api/model/request/MidasOptions';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: any;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  title: ApexTitleSubtitle;
  fill: ApexFill;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  grid: any;
  colors: any;
  toolbar: any;
};

@Component({
  selector: 'app-stockchart',
  templateUrl: './stockchart.component.html',
  styleUrls: [
    'stockchart.component.css',
    './../../../shared/styles/apexcharts.scss',
    './../../../shared/styles/ngbaccordion.scss'
  ]
})
export class StockchartComponent implements OnInit {

  apiService: TradStratApiService;
  lastMonthMidasResults: MidasResult[];

  chartsInitialized : boolean = false;
  timestampNow : number;
  timestamp90DaysAgo : number;
  numSnapshots : number;

  // Icons from font awesome
  faExpand = faCaretDown;
  faCollapse = faCaretUp;

  // ApexCharts Zoom Information
  xAxisMinimum : number;
  xAxisMaximum : number;

  // Global ApexCharts Configuration
  legend: ApexLegend = {
    position: 'bottom',
    showForSingleSeries: false,
    horizontalAlign: 'left',
    floating: true,
    offsetX: 48,
    offsetY: -24,
  };
  toolbar = {
    show: true,
    tools: {
      download: true,
      selection: false,
      zoom: false,
      zoomin: true,
      zoomout: true,
      pan: false,
      reset: '<img src="/assets/images/reset.svg" width="20">'
    }
  };
  signalAnnotations: ApexAnnotations; // See: https://apexcharts.com/angular-chart-demos/line-charts/line-chart-with-annotations/
  public commonOptions: Partial<ChartOptions> = {
    dataLabels: { enabled: false },
    stroke: { curve: "smooth", width: 2 },
    tooltip: {
      followCursor: false, theme: "light", marker: { show: false }, style: { fontSize: '11px' },
      x: { show: false },
      y: { title: { formatter: (seriesName) => seriesName } }
    },
    grid: {
      clipMarkers: false,
      padding: { top: 0, right: 0, bottom: 0, left: 0 }
    }
  };

  // Specific ApexCharts Configuration
  public daxChartOptions: Partial<ChartOptions>;
  public midasChartOptions: Partial<ChartOptions>;
  public goldChartOptions: Partial<ChartOptions>;
  public eurChartOptions: Partial<ChartOptions>;
  public volatilityChartOptions: Partial<ChartOptions>;
  public usBondsChartOptions: Partial<ChartOptions>;

  constructor(private _ngZone: NgZone, tradStratApiService: TradStratApiService, public userPermissionsService: UserPermissionsService) {
    console.log("constructor()");
    window['stockchartComponentRef'] = {component: this, zone: _ngZone};

    this.timestampNow = Math.trunc((new Date).getTime() / 1000);
    this.timestamp90DaysAgo = this.timestampNow - 7776000;

    this.xAxisMinimum = this.timestamp90DaysAgo * 1000; // Inizial Zoom;
    this.xAxisMaximum = this.timestampNow * 1000;

    this.apiService = tradStratApiService;
    if (this.lastMonthMidasResults == null || this.lastMonthMidasResults.length == 0 || !this.chartsInitialized) {
      console.log("Request last 90 days Midas results");
      this.userPermissionsService.allowCalculateMidas$.subscribe((allowed: boolean) => {
        if (allowed) { // Granted permission, read database
          const midasOptions : MidasOptions = new MidasOptions;
          midasOptions.schedulerPattern = "-1,0,1,2,3,4"; // TODO: Regard all snapshots
          midasOptions.minTimestamp = this.timestamp90DaysAgo;
          midasOptions.maxTimestamp = this.timestampNow;
          this.apiService.getMidas(midasOptions);
        }
      });
    }
  }

  ngOnInit(): void {
    console.log("ngOnInit()");
    this.apiService.midasResults$.subscribe((result: MidasResult[]) => {
      if (result != null && result != undefined && result.length > 0) {
        console.log("90 Days Midas Results present"); // TODO
        this.lastMonthMidasResults = result;
        if (this.lastMonthMidasResults != null && this.lastMonthMidasResults.length > 0 && this.chartsInitialized == false) {
          console.log("Need to reinitialize (chartsInitialized=" + this.chartsInitialized + ")");
          this.generateSignalAnnotations();
          this.initCharts();
          this.chartsInitialized = true;
          console.log("chartsInitialized was set to true");
        }
      }
    });
  }

  private generateSignalAnnotations() : void {
    this.signalAnnotations = { position: 'back' };
    this.signalAnnotations.xaxis = [];
    for (let i = 0 ; i < this.lastMonthMidasResults.length; i++) {
      const timeFrom = new Date(this.lastMonthMidasResults[i].snapshotTimestamp).getTime();
      let timeTo = (new Date).getTime();
      if (i < this.lastMonthMidasResults.length - 1) {
        timeTo = new Date(this.lastMonthMidasResults[i+1].snapshotTimestamp).getTime();
      }
      let color = "#ffffff";
      switch(this.lastMonthMidasResults[i].signal) {
        case "DARKGREEN": {color = "#005000"; break;}
        case "GREEN": {color = "#00a000"; break;}
        case "LIGHTGREEN": {color = "#00ff00"; break;}
        case "DARKYELLOW": {color = "#a0a000"; break;}
        case "YELLOW": {color = "#e6e600"; break;}
        case "LIGHTYELLOW": {color = "#ffff50"; break;}
        case "LIGHTRED": {color = "#ff5050"; break;}
        case "RED": {color = "#ff0000"; break;}
        case "DARKRED": {color = "#800000"; break;}
        default: {color = "#ffffff"; break;}
      }
      this.signalAnnotations.xaxis.push( { x: timeFrom, x2: timeTo, fillColor: color, borderColor: color, opacity: 0.75 } );
    }
  }

  publicSetZoomToAllCharts(xMin : number, xMax : number) {

  }

  private setZoomToAllCharts(xMin : number, xMax : number) {

    console.log("setZoomToAllCharts(" + xMin + ", " + xMax + ")");

    this.xAxisMinimum = xMin;
    this.xAxisMaximum = xMax;

    const daxChartOptions = this.daxChartOptions;
    const midasChartOptions = this.midasChartOptions;
    const goldChartOptions = this.goldChartOptions;
    const eurChartOptions = this.eurChartOptions;
    const volatilityChartOptions = this.volatilityChartOptions;
    const usBondsChartOptions = this.usBondsChartOptions;

    this.daxChartOptions.xaxis.min = this.xAxisMinimum; this.daxChartOptions.xaxis.max = this.xAxisMaximum;
    this.midasChartOptions.xaxis.min = this.xAxisMinimum; this.midasChartOptions.xaxis.max = this.xAxisMaximum;

    // TODO

  }


  private initCharts(): void {
    console.log("initCharts()");
    const timestampNow = this.timestampNow * 1000;
    const timestamp90DaysAgo = this.timestamp90DaysAgo * 1000;
    const zoomEvents = {
      beforeZoom: function(chartContext : any, { xaxis }) {
        console.log("beforeZoom");
        this.xAxisMaximum = timestampNow;
        if (xaxis.min < timestamp90DaysAgo) {
          return { xaxis: { min: timestamp90DaysAgo, max: timestampNow } };
        }
        return { xaxis: { min: xaxis.min, max: timestampNow } };
      },
      beforeResetZoom: (ctx, opt) => {
        console.log("beforeResetZoom");
      },
      mounted: function(chartContext, config) {
        console.log("mounted");
        // See: https://codepen.io/apexcharts/pen/BaKXaQB?editors=0010
        // See: https://apexcharts.com/docs/methods/#exec

      },
      zoomed: function(chartContext, { xaxis, yaxis }) {
        console.log("zoomed: [" + xaxis.min + ", " + xaxis.max + "]");



        console.log("setZoomToAllCharts(" + xaxis.min + ", " + xaxis.max + ")");
        // ApexCharts.exec("dax", "updateOptions", { xaxis: { min: xaxis.min, max: xaxis.max } });
        // ApexCharts.exec("midas", "updateOptions", { xaxis: { min: xaxis.min, max: xaxis.max } });

        /*
        window['stockchartComponentRef'].zone.run(() => {
          setZoomToAllCharts(xaxis.min, xaxis.max);
        });
        */

      }
    }

    this.daxChartOptions = {
      series: [
        { name: "DAX", data: this.generateDaxSeries() },
        { name: "SMA200", data: this.generateDaxSma200Series() },
        { name: "SMA100", data: this.generateDaxSma100Series() },
        { name: "SMA30", data: this.generateDaxSma30Series() },
        { name: "SMA5", data: this.generateDaxSma5Series() },
      ],
      chart: { id: "dax", group: "stock", type: "line", height: 240, toolbar: this.toolbar, events: zoomEvents },
      colors: ["#004080", "#d0d0d0", "#b0b0b0", "#909090", "#707070"],
      xaxis: { type: "datetime", position: 'bottom', labels: { offsetY: -2 }, min: this.xAxisMinimum, max: this.xAxisMaximum },
      yaxis: { tickAmount: 10, forceNiceScale: true, labels: { offsetX: -10, minWidth: 50 } },
      stroke: { width: [2, 1, 1, 1, 1], curve: ["smooth", "smooth", "smooth", "smooth", "smooth"], dashArray: [0, 9, 7, 5, 3] },
    };

    this.midasChartOptions = {
      series: [ { name: "Midas", data: this.generateMidasSeries() } ],
      chart: { id: "midas", group: "stock", type: "line", height: 133, toolbar: this.toolbar, events: zoomEvents },
      colors: ["#404040"],
      xaxis: { type: "datetime", position: 'bottom', labels: { offsetY: -2 }, min: this.xAxisMinimum, max: this.xAxisMaximum },
      yaxis: { tickAmount: 4, forceNiceScale: true, labels: { offsetX: -10, minWidth: 50 } },
      stroke: { curve: "stepline", width: 2 },
      fill: { type: 'solid', opacity: 1, },
    };


    this.goldChartOptions = {
      series: [ { name: "Gold", data: this.generateGoldSeries() } ],
      chart: { id: "gold", group: "stock", type: "area", height: 133, toolbar: this.toolbar, events: zoomEvents },
      colors: ["#008040"],
      xaxis: { type: "datetime", position: 'bottom', labels: { offsetY: -2 }, min: this.xAxisMinimum, max: this.xAxisMaximum },
      yaxis: { tickAmount: 4, forceNiceScale: true, labels: { offsetX: -10, minWidth: 50 } },
      fill: { type: 'gradient', gradient: { opacityFrom: 0.4, opacityTo: 0.1 } },
      tooltip: {
        followCursor: false, theme: "light", marker: { show: true },
        x: { show: false },
        y: { title: { formatter: (seriesName) => seriesName + " [€/Oz.]" } }
      }
    };

    this.eurChartOptions = {
      series: [ { name: "USD/EUR", data: this.generateEurSeries() } ],
      chart: { id: "eur", group: "stock",  type: "area", height: 133, toolbar: this.toolbar, events: zoomEvents },
      colors: ["#a00000"],
      xaxis: { type: "datetime", position: 'bottom', labels: { offsetY: -2 }, min: this.xAxisMinimum, max: this.xAxisMaximum },
      yaxis: { tickAmount: 4, forceNiceScale: true, labels: { offsetX: -10, minWidth: 50 } },
      fill: { type: 'gradient', gradient: { opacityFrom: 0.4, opacityTo: 0.1 } },
      tooltip: {
        followCursor: false, theme: "light", marker: { show: false },
        x: { show: false },
        y: { title: { formatter: (seriesName) => seriesName + " [USD/€]" } }
      }
    };

    this.volatilityChartOptions = {
      series: [
        { name: "VDAX New", data: this.generateVdaxSeries() },
        { name: "CBOE Volatility Index", data: this.generateVixSeries() }
      ],
      chart: { id: "volatility", group: "stock", type: "line", height: 133, toolbar: this.toolbar, events: zoomEvents },
      colors: ["#a00000", "#00a000"],
      xaxis: { type: "datetime", position: 'bottom', labels: { offsetY: -2 }, min: this.xAxisMinimum, max: this.xAxisMaximum },
      yaxis: { tickAmount: 4, forceNiceScale: true, labels: { offsetX: -10, minWidth: 50 } },
    };

    this.usBondsChartOptions = {
      series: [
        { name: "USGG 2Y", data: this.generateUsBonds2YearSeries() },
        { name: "USGG 10Y", data: this.generateUsBonds10YearSeries() },
        { name: "USGG Diff", data: this.generateUsBondsDifferenceSeries() },
      ],
      chart: { id: "usbonds", group: "stock", type: "line", height: 133, toolbar: this.toolbar, events: zoomEvents },
      colors: ["#a00000", "#00a000", "#0000a0"],
      xaxis: { type: "datetime", position: 'bottom', labels: { offsetY: -2 }, min: this.xAxisMinimum, max: this.xAxisMaximum },
      yaxis: { tickAmount: 4, forceNiceScale: true, labels: { offsetX: -10, minWidth: 50 } },
    };
  }

  private generateDaxSeries() : any[] {
    let series = [];
    for (let i = 0 ; i < this.lastMonthMidasResults.length; i++) {
      series.push([(this.lastMonthMidasResults[i].snapshotTimestamp).valueOf(), this.lastMonthMidasResults[i].daxCurrent]);
    }
    return series;
  }

  private generateDaxSma200Series() : any[] {
    let series = [];
    for (let i = 0 ; i < this.lastMonthMidasResults.length; i++) {
      series.push([(this.lastMonthMidasResults[i].snapshotTimestamp).valueOf(), this.lastMonthMidasResults[i].daxGd200Current]);
    }
    return series;
  }

  private generateDaxSma100Series() : any[] {
    let series = [];
    for (let i = 0 ; i < this.lastMonthMidasResults.length; i++) {
      series.push([(this.lastMonthMidasResults[i].snapshotTimestamp).valueOf(), this.lastMonthMidasResults[i].daxGd100Current]);
    }
    return series;
  }

  private generateDaxSma30Series() : any[] {
    let series = [];
    for (let i = 0 ; i < this.lastMonthMidasResults.length; i++) {
      series.push([(this.lastMonthMidasResults[i].snapshotTimestamp).valueOf(), this.lastMonthMidasResults[i].daxGd30Current]);
    }
    return series;
  }

  private generateDaxSma5Series() : any[] {
    let series = [];
    for (let i = 0 ; i < this.lastMonthMidasResults.length; i++) {
      series.push([(this.lastMonthMidasResults[i].snapshotTimestamp).valueOf(), this.lastMonthMidasResults[i].daxGd5Current]);
    }
    return series;
  }

  private generateGoldSeries() : any[] {
    let series = [];
    for (let i = 0 ; i < this.lastMonthMidasResults.length; i++) {
      series.push([(this.lastMonthMidasResults[i].snapshotTimestamp).valueOf(), this.lastMonthMidasResults[i].xauCurrent]);
    }
    return series;
  }

  private generateEurSeries() : any[] {
    let series = [];
    for (let i = 0 ; i < this.lastMonthMidasResults.length; i++) {
      series.push([(this.lastMonthMidasResults[i].snapshotTimestamp).valueOf(), this.lastMonthMidasResults[i].eurUsdCurrent]);
    }
    return series;
  }

  private generateVdaxSeries() : any[] {
    let series = [];
    for (let i = 0 ; i < this.lastMonthMidasResults.length; i++) {
      series.push([(this.lastMonthMidasResults[i].snapshotTimestamp).valueOf(), this.lastMonthMidasResults[i].vdaxNewCurrent]);
    }
    return series;
  }

  private generateVixSeries() : any[] {
    let series = [];
    for (let i = 0 ; i < this.lastMonthMidasResults.length; i++) {
      series.push([(this.lastMonthMidasResults[i].snapshotTimestamp).valueOf(), this.lastMonthMidasResults[i].vixCurrent.toFixed(2)]);
    }
    return series;
  }

  private generateUsBonds2YearSeries() : any[] {
    let series = [];
    for (let i = 0 ; i < this.lastMonthMidasResults.length; i++) {
      series.push([(this.lastMonthMidasResults[i].snapshotTimestamp).valueOf(), this.lastMonthMidasResults[i].usGg2YrCurrent.toFixed(4)]);
    }
    return series;
  }

  private generateUsBonds10YearSeries() : any[] {
    let series = [];
    for (let i = 0 ; i < this.lastMonthMidasResults.length; i++) {
      series.push([(this.lastMonthMidasResults[i].snapshotTimestamp).valueOf(), this.lastMonthMidasResults[i].usGg10YrCurrent.toFixed(4)]);
    }
    return series;
  }

  private generateUsBondsDifferenceSeries() : any[] {
    let series = [];
    for (let i = 0 ; i < this.lastMonthMidasResults.length; i++) {
      series.push([(this.lastMonthMidasResults[i].snapshotTimestamp).valueOf(), (this.lastMonthMidasResults[i].usGg10YrCurrent - this.lastMonthMidasResults[i].usGg2YrCurrent).toFixed(4)]);
    }
    return series;
  }

  private generateMidasSeries() : any[] {
    let series = [];
    for (let i = 0 ; i < this.lastMonthMidasResults.length; i++) {
      series.push([(this.lastMonthMidasResults[i].snapshotTimestamp).valueOf(), this.lastMonthMidasResults[i].result]);
    }
    return series;
  }

}
