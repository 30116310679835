export class Thresholds {

  signalToDarkGreenMidasThreshold : number;
  signalToGreenMidasThreshold : number;
  signalToLightGreenMidasThreshold : number;
  signalToDarkYellowMidasThreshold : number;
  signalToYellowMidasThreshold : number;
  signalToLightYellowMidasThreshold : number;
  signalToLightRedMidasThreshold : number;
  signalToRedMidasThreshold : number;

}
