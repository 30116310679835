import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'encodeURI'})
export class EncodeURIPipe implements PipeTransform {

  constructor() {
  }

  public transform(uri: string): string {
    return encodeURI(uri);
  }

}
