export const environment = {
  production: true,
  appVersion: '2.2.0',
  auth: {
    clientID: 'bv6JtnWqUSilPUqxmYbGZAKe8WEqXvlj',
    domain: 'dev-2hkjszvc.eu.auth0.com',
    audience: 'https://tradstrat.de',
    namespace: 'https://tradstrat.de'
  },
  tradStratApiUrl: 'https://api.midasampel.de',
  // tradStratApiUrl: 'http://localhost:5000',
  verificationMail: {
    apiUrl: 'https://85dfwd7wyh.execute-api.eu-central-1.amazonaws.com/prod',
    apiKey: '71gymqEJAc903OE5tq3cN9WpnOdEQLqTS7DZ9bXf'
  }
};
