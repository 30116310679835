export class Weights {

  daxChange5DaysIndicatorWeight : number; // Default: 1.0
  daxGdCompareDaxCurrentIndicatorWeight : number; // Default: 1.0
  daxGd5Change5DaysIndicatorWeight : number; // Default: 1.0
  daxGd30CompareIndicatorWeight : number; // Default: 1.0
  daxGd100CompareIndicatorWeight : number; // Default: 1.0
  daxGd200CompareIndicatorWeight : number; // Default: 1.0
  vdaxNewIndicatorWeight : number; // Default: 1.0
  eurUsdChange5DaysIndicatorWeight : number; // Default: 1.0
  xauChange5DaysIndicatorWeight : number; // Default: 1.0
  vixIndicatorWeight : number; // Default: 1.0
  usBondsIndicatorWeight : number; // Default: 1.0

  constructor() {
    this.daxChange5DaysIndicatorWeight = 1.0;
    this.daxGdCompareDaxCurrentIndicatorWeight = 1.0;
    this.daxGd5Change5DaysIndicatorWeight = 1.0;
    this.daxGd30CompareIndicatorWeight = 1.0;
    this.daxGd100CompareIndicatorWeight = 1.0;
    this.daxGd200CompareIndicatorWeight = 1.0;
    this.vdaxNewIndicatorWeight = 1.0;
    this.eurUsdChange5DaysIndicatorWeight = 1.0;
    this.xauChange5DaysIndicatorWeight = 1.0;
    this.vixIndicatorWeight = 1.0;
    this.usBondsIndicatorWeight = 1.0;
  }

  public isAllDefault(): boolean {
    return (this.daxChange5DaysIndicatorWeight === 1.0
      && this.daxGdCompareDaxCurrentIndicatorWeight === 1.0
      && this.daxGd5Change5DaysIndicatorWeight === 1.0
      && this.daxGd30CompareIndicatorWeight === 1.0
      && this.daxGd100CompareIndicatorWeight === 1.0
      && this.daxGd200CompareIndicatorWeight === 1.0
      && this.vdaxNewIndicatorWeight === 1.0
      && this.eurUsdChange5DaysIndicatorWeight === 1.0
      && this.xauChange5DaysIndicatorWeight === 1.0
      && this.vixIndicatorWeight === 1.0
      && this.usBondsIndicatorWeight === 1.0)
  }

}
