import { Component, OnInit } from '@angular/core';
import { SpinnerService } from './spinner.service';
import { SpinnerContent } from './SpinnerContent';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  // content: SpinnerContent = {title: null, message: null, cancelButton: false, cancelText: null};

  constructor(private spinnerService: SpinnerService) { }

  ngOnInit(): void {
    // this.spinnerService.contentChanged.subscribe((content: SpinnerContent) => this.onContentChanged(content));
    this.spinnerService.visibilityChanged.subscribe((visible: boolean) => this.onVisibilityChanged(visible));
    this.spinnerService.marginTopChanged.subscribe((margin: number) => this.onMarginTopChanged(margin));
  }

  /*
  private onContentChanged(content: SpinnerContent): void {
    this.content = content;
  }
  */

  private onVisibilityChanged(visible: boolean): void {
    const spinnerOverlay = document.querySelector('.spinner-overlay');
    if (spinnerOverlay) {
      if (visible) {
        spinnerOverlay.setAttribute('style', 'display: block !important;');
      } else {
        spinnerOverlay.setAttribute('style', 'display: none !important;');
      }
    }
  }

  private onMarginTopChanged(margin: number): void {
    const spinnerOverlay = document.querySelector('.spinner-overlay');
    if (spinnerOverlay) {
      spinnerOverlay.setAttribute('style', 'margin-top: ' + margin + 'px;');
    }
  }

  /*
  cancelClick($event?: any): void {
    this.spinnerService.cancelClicked.emit();
  }
  */

}
