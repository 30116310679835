<div class="main-container">

  <!-- NAVIGATION BAR -->
  <app-navigation></app-navigation> <!-- class="fixed-top" -->

  <div class="main-content" id="mainContent">
    <!-- SPINNER OVERLAY -->
    <app-spinner></app-spinner>

    <br>

    <!-- DYNAMIC ROUTED CONTENT -->
    <router-outlet class="content mt-5"></router-outlet>

    <br><br>

  </div>

  <!-- FOOTER / STATUS BAR -->
  <app-footer></app-footer>

</div>
