import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatSliderChange } from '@angular/material/slider';
import { Weights } from 'src/app/tradstrat-api/model/request/Weights';

@Component({
  selector: 'app-weights',
  templateUrl: './weights.component.html',
  styleUrls: ['./weights.component.scss']
})
export class WeightsComponent implements OnInit {

  @Output() selectedWeightsEvent = new EventEmitter<Weights>();

  weights: Weights;

  daxChange5DaysIndicatorWeight = 1.0;
  daxGdCompareDaxCurrentIndicatorWeight = 1.0;
  daxGd5Change5DaysIndicatorWeight = 1.0;
  daxGd30CompareIndicatorWeight = 1.0;
  daxGd100CompareIndicatorWeight = 1.0;
  daxGd200CompareIndicatorWeight = 1.0;
  vdaxNewIndicatorWeight = 1.0;
  eurUsdChange5DaysIndicatorWeight = 1.0;
  xauChange5DaysIndicatorWeight = 1.0;
  vixIndicatorWeight = 1.0;
  usBondsIndicatorWeight = 1.0;


  constructor() {
    this.weights = new Weights();
    this.weights.daxChange5DaysIndicatorWeight = 1.0;
    this.weights.daxGdCompareDaxCurrentIndicatorWeight = 1.0;
    this.weights.daxGd5Change5DaysIndicatorWeight = 1.0;
    this.weights.daxGd30CompareIndicatorWeight = 1.0;
    this.weights.daxGd100CompareIndicatorWeight = 1.0;
    this.weights.daxGd200CompareIndicatorWeight = 1.0;
    this.weights.vdaxNewIndicatorWeight = 1.0;
    this.weights.eurUsdChange5DaysIndicatorWeight = 1.0;
    this.weights.xauChange5DaysIndicatorWeight = 1.0;
    this.weights.vixIndicatorWeight = 1.0;
    this.weights.usBondsIndicatorWeight = 1.0;
  }


  ngOnInit(): void {
  }

  onSliderChange(event: MatSliderChange): void {
    // console.log("change(event=" + event.source._elementRef.nativeElement.id + "->" + event.value + ")");
    switch (event.source._elementRef.nativeElement.id) {
      case "1": { this.daxChange5DaysIndicatorWeight = event.value; break; }
      case "2": { this.daxGdCompareDaxCurrentIndicatorWeight = event.value; break; }
      case "3": { this.daxGd5Change5DaysIndicatorWeight = event.value; break; }
      case "4": { this.daxGd30CompareIndicatorWeight = event.value; break; }
      case "5": { this.daxGd100CompareIndicatorWeight = event.value; break; }
      case "6": { this.daxGd200CompareIndicatorWeight = event.value; break; }
      case "7": { this.vdaxNewIndicatorWeight = event.value; break; }
      case "8": { this.eurUsdChange5DaysIndicatorWeight = event.value; break; }
      case "9": { this.xauChange5DaysIndicatorWeight = event.value; break; }
      case "10": { this.vixIndicatorWeight = event.value; break; }
      case "11": { this.usBondsIndicatorWeight = event.value; break; }
      default: { break; }
    }

    this.weights.daxChange5DaysIndicatorWeight = this.daxChange5DaysIndicatorWeight;
    this.weights.daxGdCompareDaxCurrentIndicatorWeight = this.daxGdCompareDaxCurrentIndicatorWeight;
    this.weights.daxGd5Change5DaysIndicatorWeight = this.daxGd5Change5DaysIndicatorWeight;
    this.weights.daxGd30CompareIndicatorWeight = this.daxGd30CompareIndicatorWeight;
    this.weights.daxGd100CompareIndicatorWeight = this.daxGd100CompareIndicatorWeight;
    this.weights.daxGd200CompareIndicatorWeight = this.daxGd200CompareIndicatorWeight;
    this.weights.vdaxNewIndicatorWeight = this.vdaxNewIndicatorWeight;
    this.weights.eurUsdChange5DaysIndicatorWeight = this.eurUsdChange5DaysIndicatorWeight;
    this.weights.xauChange5DaysIndicatorWeight = this.xauChange5DaysIndicatorWeight;
    this.weights.vixIndicatorWeight = this.vixIndicatorWeight;
    this.weights.usBondsIndicatorWeight = this.usBondsIndicatorWeight;

    this.selectedWeightsEvent.emit(this.weights);

  }

}
