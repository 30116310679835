export class DateRange {
  private fromDate: Date = null;
  private toDate: Date = null;

  fromTimeStamp: number = 0;
  toTimeStamp: number = 0;
  dateRangeString: string; // Date range summary

  constructor(fromDate: Date, toDate: Date) {
    this.fromDate = fromDate;
    this.toDate = toDate;
    this.calculateTimeStamps();
    this.calculateDateRangeString();
  }

  public setFromDate(date: Date): void {
    this.fromDate = date;
    this.calculateTimeStamps();
    this.calculateDateRangeString();
  }

  public getFromDate(): Date {
    return this.fromDate;
  }

  public setToDate(date: Date): void {
    this.calculateTimeStamps();
    this.calculateDateRangeString();
    this.toDate = date;
  }

  public getToDate(): Date {
    return this.toDate;
  }

  private calculateTimeStamps(): void {
    if (this.fromDate !== null) {
      this.fromTimeStamp = Math.round(this.fromDate.valueOf() / 1000);
    } else {
      this.fromTimeStamp = 0;
    }
    if (this.toDate !== null) {
      this.toTimeStamp = Math.round(this.toDate.valueOf() / 1000);
    } else {
      this.toTimeStamp = 0;
    }
  }

  private calculateDateRangeString(): void {
    if (this.fromDate !== null && this.toDate !== null) {
      this.dateRangeString =
          this.fromDate.getUTCDate() + "." + (this.fromDate.getUTCMonth() + 1) + "." + this.fromDate.getUTCFullYear() + " - " + this.toDate.getUTCDate() + "." + (this.toDate.getUTCMonth() + 1) + "." + this.toDate.getUTCFullYear();
    } else {
      this.dateRangeString = "---";
    }
  }

}
