import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '../shared/services/window-ref';
import { LocalRuntimeEnvironment } from '../shared/services/runtime-environment.service';

interface Auth0Config {
  clientID: string;
  domain: string;
  audience: string;
  callbackUrl: string;
  logoutUrl: string;
  namespace: string;
}

@Injectable({
  providedIn: 'root'
})
export class Auth0ConfigClass implements Auth0Config {

  constructor(@Inject(WINDOW) private window: Window, private localRuntimeEnvironment: LocalRuntimeEnvironment) {
  }

  get clientID(): string {
    return this.localRuntimeEnvironment.getValueFor('auth')?.clientID || '';
  }

  get domain(): string {
    return this.localRuntimeEnvironment.getValueFor('auth')?.domain || '';
  }

  get audience(): string {
    return this.localRuntimeEnvironment.getValueFor('auth')?.audience || '';
  }

  get baseUrl(): string {
    return '';
  }

  get callbackUrl(): string {
    return `${this.window.location.origin}` + this.baseUrl + '/callback';
  }

  get logoutUrl(): string {
    // return `${this.window.location.origin}` + this.baseUrl + '/logout';
    return `${this.window.location.origin}` + this.baseUrl + '/dashboard';
  }

  get namespace(): string {
    return this.localRuntimeEnvironment.getValueFor('auth')?.namespace || '';
  }

}
