import { Injectable, EventEmitter } from '@angular/core';
import { FooterContent } from './FooterContent';

@Injectable({providedIn: 'root'})
export class FooterService {

  public footerContentChanged: EventEmitter<FooterContent> = new EventEmitter();

  constructor() {
  }

  setContent(content: FooterContent): void {
    this.footerContentChanged.emit(content);
  }

}
