import { MidasProperties } from "./MidasProperties";

export class MidasOptions {

  onlyScheduled : boolean; // true, if only scheduled Snapshots have to be regarded
  schedulerPattern : string; // e.g. "0|1,2,3|4"

  timestamp : number;
  minTimestamp : number;
  maxTimestamp : number;

  midasProperties : MidasProperties;

}
