import { TradingComponent } from './trading/trading.component';
import { SimulationComponent } from './simulation/simulation.component';
import { Auth0CallbackComponent } from './auth/callback/auth-callback.component';
import { MidasComponent } from './midas/midas.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { Auth0LogoutComponent } from './auth/callback/auth-logout.component';

export const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: { reuseRoute: true } // Flag, that determines, if this path should be reused
  },
  {
    path: 'callback', component: Auth0CallbackComponent,
    data: { reuseRoute: false } // Flag, that determines, if this path should be reused
  },
  {
    path: 'logout', component: Auth0LogoutComponent,
    data: { reuseRoute: false } // Flag, that determines, if this path should be reused
  },
  {
    path: 'midas',
    component: MidasComponent,
    canActivate: [AuthGuard],
    data: { reuseRoute: true } // Flag, that determines, if this path should be reused
  },
  {
    path: 'simulation',
    component: SimulationComponent,
    canActivate: [AuthGuard],
    data: { reuseRoute: true } // Flag, that determines, if this path should be reused
  },
  {
    path: 'trading',
    component: TradingComponent,
    canActivate: [AuthGuard],
    data: { reuseRoute: true } // Flag, that determines, if this path should be reused
  },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: false, initialNavigation: true } )
  ],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule { }
