import { Leverages } from '../../../tradstrat-api/model/request/Leverages';
import { Thresholds } from '../../../tradstrat-api/model/request/Thresholds';
import { Preset } from './Preset';

export class PresetProvider {

  availablePresets: string[] = ["Defensiv", "Offensiv", "Offensiv Variante", "Hasardeur", "PRESET_USER_DEFINED"]
  defaultPreset: string = "Offensiv Variante";

  public static getPreset(presetName: string) : Preset {

    if (presetName === "PRESET_USER_DEFINED") {
      return null;
    }

    const thresholds = new Thresholds();
    const leverages = new Leverages();
    let preset: string = presetName;
    switch(presetName) {
      case "Defensiv": {
        thresholds.signalToDarkGreenMidasThreshold = 9;
        thresholds.signalToGreenMidasThreshold = 5;
        thresholds.signalToLightGreenMidasThreshold = 3;
        thresholds.signalToDarkYellowMidasThreshold = 2;
        thresholds.signalToYellowMidasThreshold = 0;
        thresholds.signalToLightYellowMidasThreshold = -2;
        thresholds.signalToLightRedMidasThreshold = -4;
        thresholds.signalToRedMidasThreshold = -6;
        leverages.leverageWhenSignalIsDarkGreen = 0.25;
        leverages.leverageWhenSignalIsGreen = 1.5;
        leverages.leverageWhenSignalIsLightGreen = 1.25;
        leverages.leverageWhenSignalIsDarkYellow = 1;
        leverages.leverageWhenSignalIsYellow = 1;
        leverages.leverageWhenSignalIsLightYellow = 1;
        leverages.leverageWhenSignalIsLightRed = 0.25;
        leverages.leverageWhenSignalIsRed = -0.5;
        leverages.leverageWhenSignalIsDarkRed = 0.25;
        break;
      }
      case "Offensiv": {
        thresholds.signalToDarkGreenMidasThreshold = 9;
        thresholds.signalToGreenMidasThreshold = 5;
        thresholds.signalToLightGreenMidasThreshold = 3;
        thresholds.signalToDarkYellowMidasThreshold = 2;
        thresholds.signalToYellowMidasThreshold = 0;
        thresholds.signalToLightYellowMidasThreshold = -2;
        thresholds.signalToLightRedMidasThreshold = -4;
        thresholds.signalToRedMidasThreshold = -6;
        leverages.leverageWhenSignalIsDarkGreen = -1;
        leverages.leverageWhenSignalIsGreen = 2;
        leverages.leverageWhenSignalIsLightGreen = 2;
        leverages.leverageWhenSignalIsDarkYellow = 1;
        leverages.leverageWhenSignalIsYellow = 1;
        leverages.leverageWhenSignalIsLightYellow = 1;
        leverages.leverageWhenSignalIsLightRed = 0;
        leverages.leverageWhenSignalIsRed = -2;
        leverages.leverageWhenSignalIsDarkRed = 1;
        break;
      }
      case "Offensiv Variante": {
        thresholds.signalToDarkGreenMidasThreshold = 9;
        thresholds.signalToGreenMidasThreshold = 5;
        thresholds.signalToLightGreenMidasThreshold = 3;
        thresholds.signalToDarkYellowMidasThreshold = 2;
        thresholds.signalToYellowMidasThreshold = 0;
        thresholds.signalToLightYellowMidasThreshold = -2;
        thresholds.signalToLightRedMidasThreshold = -4;
        thresholds.signalToRedMidasThreshold = -6;
        leverages.leverageWhenSignalIsDarkGreen = -1;
        leverages.leverageWhenSignalIsGreen = 3;
        leverages.leverageWhenSignalIsLightGreen = 2;
        leverages.leverageWhenSignalIsDarkYellow = 1.5;
        leverages.leverageWhenSignalIsYellow = 1.25;
        leverages.leverageWhenSignalIsLightYellow = 1;
        leverages.leverageWhenSignalIsLightRed = 0;
        leverages.leverageWhenSignalIsRed = -0.25;
        leverages.leverageWhenSignalIsDarkRed = 1;
        break;
      }
      case "Hasardeur": {
        thresholds.signalToDarkGreenMidasThreshold = 9;
        thresholds.signalToGreenMidasThreshold = 5;
        thresholds.signalToLightGreenMidasThreshold = 3;
        thresholds.signalToDarkYellowMidasThreshold = 2;
        thresholds.signalToYellowMidasThreshold = 0;
        thresholds.signalToLightYellowMidasThreshold = -2;
        thresholds.signalToLightRedMidasThreshold = -4;
        thresholds.signalToRedMidasThreshold = -6;
        leverages.leverageWhenSignalIsDarkGreen = -2;
        leverages.leverageWhenSignalIsGreen = 3;
        leverages.leverageWhenSignalIsLightGreen = 2.5;
        leverages.leverageWhenSignalIsDarkYellow = 2;
        leverages.leverageWhenSignalIsYellow = 1.5;
        leverages.leverageWhenSignalIsLightYellow = 1;
        leverages.leverageWhenSignalIsLightRed = 0.5;
        leverages.leverageWhenSignalIsRed = -1;
        leverages.leverageWhenSignalIsDarkRed = 2;
        break;
      }
      default: { // Offensiv Variante
        thresholds.signalToDarkGreenMidasThreshold = 9;
        thresholds.signalToGreenMidasThreshold = 5;
        thresholds.signalToLightGreenMidasThreshold = 3;
        thresholds.signalToDarkYellowMidasThreshold = 2;
        thresholds.signalToYellowMidasThreshold = 0;
        thresholds.signalToLightYellowMidasThreshold = -2;
        thresholds.signalToLightRedMidasThreshold = -4;
        thresholds.signalToRedMidasThreshold = -6;
        leverages.leverageWhenSignalIsDarkGreen = -1;
        leverages.leverageWhenSignalIsGreen = 3;
        leverages.leverageWhenSignalIsLightGreen = 2;
        leverages.leverageWhenSignalIsDarkYellow = 1.5;
        leverages.leverageWhenSignalIsYellow = 1.25;
        leverages.leverageWhenSignalIsLightYellow = 1;
        leverages.leverageWhenSignalIsLightRed = 0;
        leverages.leverageWhenSignalIsRed = -0.25;
        leverages.leverageWhenSignalIsDarkRed = 1;
        preset = "Offensiv Variante";
        break;
      }
    }
    return new Preset(thresholds, leverages, preset);
  }

}
