import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'replaceDotByComma', pure: false })
export class ReplaceDotByComma implements PipeTransform {

  constructor() {
  }

  public transform(content: any): string {
    try {
      return content.toString().replace('.', ',');
    } catch (error) {
      return undefined;
    }
  }

}
