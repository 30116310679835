import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { BehaviorSubject } from 'rxjs';

// tslint:disable: no-string-literal tslint:disable: max-line-length

@Injectable( { providedIn: 'root' } )
export class VerificationMailService {

  private authService: AuthService;
  private apiUrl: string;
  private apiHeaders: HttpHeaders;
  private requestSuccessSubject$ = new BehaviorSubject<boolean>(null);
  requestSuccess$ = this.requestSuccessSubject$.asObservable();
  requestSuccess: boolean = null;

  constructor(private http: HttpClient, authService: AuthService) {
    this.authService = authService;
    this.apiUrl = environment.verificationMail.apiUrl;
    this.apiHeaders = new HttpHeaders()
      .set('x-api-key', environment.verificationMail.apiKey)
      .set('Content-Type', 'application/json');
  }

  requestVerificationMail(): void {
    this.authService.userProfile$.subscribe((profile: any) => {
      const userId: string = profile['user_id'] ? profile['user_id'] : (profile['sub'] ? profile['sub'] : null);
      const clientId: string = environment.auth.clientID;
      if (!userId) {
        console.error('Can\'t re-request verification e-Mail: User ID is not available from user profile');
        return;
      }
      if (!clientId) {
        console.error('Can\'t re-request verification e-Mail: Client ID is not available from environment');
        return;
      }
      const requestBody = '{\n  "user_id": "' + userId + '",\n  "client_id": "' + clientId + '"\n}';
      console.log('requestBody: ' + requestBody); // DEBUG ONLY
      this.http.post<any>(this.apiUrl, requestBody, { headers: this.apiHeaders, observe: 'response' }).subscribe((response: HttpResponse<any>) => {
        console.log(JSON.stringify(response)); // DEBUG ONLY
        if (response.status.valueOf() === 201) {
          console.log('Verificatien e-Mail successfully re-requested');
          this.requestSuccessSubject$.next(true);
          this.requestSuccess = true;
        } else {
          console.error('Verificatien e-Mail re-request failed <Status: ' + response.status.valueOf() + '>');
          this.requestSuccessSubject$.next(false);
          this.requestSuccess = false;
        }
      },
      (error: HttpErrorResponse) => {
        console.error('Verificatien e-Mail re-request failed', error);
        this.requestSuccessSubject$.next(false);
        this.requestSuccess = false;
      });
    });
  }

}
