import { StockSnapshot } from './../tradstrat-api/model/StockSnapshot';
import { VerificationMailService } from './../auth/verifcation-mail.service';
import { SpinnerService } from '../shared/components/spinner/spinner.service';
import { UserPermissionsService } from '../auth/permissions.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { MidasResult } from '../tradstrat-api/model/MidasResult';
import { TradStratApiService } from '../tradstrat-api/api.service';
import { faArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons';
import { MidasOptions } from '../tradstrat-api/model/request/MidasOptions';

// tslint:disable: max-line-length // tslint:disable: no-unused-expression

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: [
    './dashboard.component.scss',
    './../shared/styles/tradstrat.scss',
    './../shared/styles/globalColours.scss'
  ]
})
export class DashboardComponent implements OnInit {

  apiService: TradStratApiService;
  emailService: VerificationMailService;

  latestMidasResult: MidasResult;
  latestStockSnapshot: StockSnapshot;
  missingPermissions = false;
  missingEmailVerification = false;
  verificationEmailRequested = false;

  // Icons from font awesome
  faNavigate = faArrowAltCircleRight;

  constructor(emailService: VerificationMailService, private router: Router, public authService: AuthService, public userPermissionsService: UserPermissionsService, public spinnerService: SpinnerService, tradStratApiService: TradStratApiService) {
    this.apiService = tradStratApiService;
    this.emailService = emailService;
  }

  ngOnInit(): void {
    this.authService.loginInProgress$.getValue() === true ? this.spinnerService.startSpin() : null;
    this.authService.loginInProgress$.subscribe((loginInProgress: boolean) => {
      loginInProgress ? this.spinnerService.startSpin() : this.spinnerService.stopSpin();
    });
    this.apiService.latestMidasResult$.subscribe((result: MidasResult) => {
      this.latestMidasResult = result;
    });
    this.apiService.latestStockSnapshot$.subscribe((result: StockSnapshot) => {
      this.latestStockSnapshot = result;
    });

    // Fix redirect on reload after Auth0 PKCE authentication
    // if (sessionStorage.getItem('redirectComplete')) {
    console.log('Redirect after login complete: ' + [sessionStorage.getItem('redirectComplete')]);
    sessionStorage.removeItem('redirectComplete');
    if (sessionStorage.getItem('lastRequestedRoute')) {
      console.log('Last requested Route: ' + [sessionStorage.getItem('lastRequestedRoute')]);
      this.router.navigate([sessionStorage.getItem('lastRequestedRoute')]);
      sessionStorage.removeItem('lastRequestedRoute');
    }
    // }
    // Check for needed permissions
    this.userPermissionsService.permissionsCheckComplete$.subscribe((complete: boolean) => {
      if (complete && this.authService.loggedIn) {
        if (this.userPermissionsService.allowReadStock === false ||
          this.userPermissionsService.allowCalculateMidas === false ||
          this.userPermissionsService.allowCalculateBacktest === false ||
          this.userPermissionsService.allowReadTrades === false) {
            this.missingPermissions = true;
        }
        if (this.userPermissionsService.emailVerified === false) {
          this.missingEmailVerification = true;
        }
      }
    });

    this.userPermissionsService.allowCalculateMidas$.subscribe((allowed: boolean) => {
      if (allowed) { // Granted permission, read database and calculate midas
        let midasOptions : MidasOptions = new MidasOptions;
        midasOptions.schedulerPattern = "1|0,2,4|3"; // TODO
        this.apiService.getLatestMidas(midasOptions);
      }
    });

    this.userPermissionsService.allowReadStock$.subscribe((allowed: boolean) => {
      if (allowed) { // Granted permission, read stock snapshots from database
        this.apiService.getLatestSnapshotFromDatabase();
      }
    });
  }

  requestVerificationMail(): void {
    this.emailService.requestSuccess$.subscribe((success: boolean) => {
      this.verificationEmailRequested = success;
    });
    this.emailService.requestVerificationMail();
  }

  calcDaxChange(): string {
    return this.calcChange(this.latestStockSnapshot.daxLast6ValueCurrent, this.latestStockSnapshot.daxLast6ValueMinus1, 2);
  }
  calcDaxChangePercent(): string {
    return this.calcChangePercent(this.latestStockSnapshot.daxLast6ValueCurrent, this.latestStockSnapshot.daxLast6ValueMinus1, 2);
  }
  calcGoldChange(): string {
    return this.calcChange(this.latestStockSnapshot.goldLast6ValueCurrent, this.latestStockSnapshot.goldLast6ValueMinus1, 2);
  }
  calcGoldChangePercent(): string {
    return this.calcChangePercent(this.latestStockSnapshot.goldLast6ValueCurrent, this.latestStockSnapshot.goldLast6ValueMinus1, 2);
  }
  calcEurUsdChange(): string {
    return this.calcChange(this.latestStockSnapshot.eurLast6ValueCurrent, this.latestStockSnapshot.eurLast6ValueMinus1, 2);
  }
  calcEurUsdChangePercent(): string {
    return this.calcChangePercent(this.latestStockSnapshot.eurLast6ValueCurrent, this.latestStockSnapshot.eurLast6ValueMinus1, 2);
  }

  private calcChange(newValue: number, oldValue: number, numDigits: number): string {
    const exponent = Math.pow(10, numDigits);
    try {
      const change = Math.round((newValue - oldValue) * exponent) / exponent;
      if (change > 0) {
        return '<span style="color: darkgreen;">+' + change.toString().replace('.', ',') + '</span>';
      }
      return '<span style="color: red;">' + change.toString().replace('.', ',') + '</span>';
    } catch (error) {
      return undefined;
    }
  }

  private calcChangePercent(newValue: number, oldValue: number, numDigits: number): string {
    const exponent = Math.pow(10, numDigits);
    try {
      const change = Math.round(((newValue - oldValue) / oldValue) * 100 * exponent) / exponent;
      if (change > 0) {
        return '<span style="color: darkgreen;">+' + change.toString().replace('.', ',') + '%</span>';
      }
      return '<span style="color: red;">' + change.toString().replace('.', ',') + '%</span>';
    } catch (error) {
      return undefined;
    }
  }

}

