import { Leverages } from './../../../tradstrat-api/model/request/Leverages';
import { Thresholds } from './../../../tradstrat-api/model/request/Thresholds';

export class Preset {
  thresholds: Thresholds;
  leverages: Leverages;
  preset: string;

  constructor(thresholds: Thresholds, leverages: Leverages, preset: string) {
    this.thresholds = thresholds;
    this.leverages = leverages;
    this.preset = preset;
  }

}
