export class Leverages {

  leverageWhenSignalIsDarkGreen : number;
  leverageWhenSignalIsGreen : number;
  leverageWhenSignalIsLightGreen : number;
  leverageWhenSignalIsDarkYellow : number;
  leverageWhenSignalIsYellow : number;
  leverageWhenSignalIsLightYellow : number;
  leverageWhenSignalIsLightRed : number;
  leverageWhenSignalIsRed : number;
  leverageWhenSignalIsDarkRed : number;

}
