import { TradeSummaryResult } from '../tradstrat-api/model/TradeSummaryResult';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { UserPermissionsService } from 'src/app/auth/permissions.service';
import { TradStratApiService } from 'src/app/tradstrat-api/api.service';
import { SpinnerService } from '../shared/components/spinner/spinner.service';

@Component({
  selector: 'app-trading',
  templateUrl: './trading.component.html',
  styleUrls: ['./trading.component.scss']
})
export class TradingComponent implements OnInit {

  private apiService: TradStratApiService;
  private spinnerService: SpinnerService;
  private permissionServive: UserPermissionsService;

  tradeSummaryResult: TradeSummaryResult;
  spinners: number;
  permitted: boolean = false;

  constructor(public authService: AuthService, userPermissionsService: UserPermissionsService, tradStratApiService: TradStratApiService, spinnerService: SpinnerService) {
    this.apiService = tradStratApiService;
    this.spinnerService = spinnerService;
    this.permissionServive = userPermissionsService;
    this.apiService.tradeSummary$.subscribe((result: TradeSummaryResult) => {
      this.tradeSummaryResult = result;
      this.reduceSpinner();
    });
  }

  ngOnInit(): void {
    this.permissionServive.allowReadTrades$.subscribe((allowed: boolean) => {
      if (allowed) { // Granted permission, get summary
        // console.log('Granted permission, get summary'); // DEBUG
        this.initContent();
      }
    });
  }

  private initContent(): void {
    this.spinners = 1;
    this.spinnerService.startSpin();
    this.apiService.getTradingApiSummary();
    this.permitted = true;
  }

  private reduceSpinner(): void {
    this.spinners -= 1;
    if (this.spinners <= 0) {
      this.spinners = 0;
      this.spinnerService.stopSpin();
    }
  }



}
