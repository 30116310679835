<!-- See:
  https://getbootstrap.com/docs/4.0/components/navbar/
  https://mdbootstrap.com/docs/jquery/navigation/hamburger-menu/
  https://getbootstrap.com/docs/4.0/components/navbar/
-->

<!-- STICKY-TOP NAVIGATION BAR -->
<nav class="navbar navbar-toggleable sticky-top navbar-light bg-light pt-0 pb-0 mt-0 mb-0">
  <div class="navbar-brand" style="overflow: visible !important;">

    <!-- LOGIN/LOGOUT -->
    <div *ngIf="!authService.loggedIn" class="d-inline-block">
      <span id="login-link" class="login-link" title="{{ 'LOGIN' | translate }}" (click)="doLogin()" (focus)="doLogin()">
        <fa-icon [icon]="faLogin" class="icon-link mr-4"></fa-icon> <!-- {{ 'LOGIN' | translate }} -->
      </span>
    </div>
    <div ngbDropdown *ngIf="authService.loggedIn" class="d-inline-block" #loginDropdown="ngbDropdown">
      <span ngbDropdownToggle id="logout-link" class="logout-link"
        title="{{ 'LOGOUT' | translate }}"
        (click)="closeAllDropdowns($event); loginDropdown.open();"
        (focus)="closeAllDropdowns($event); loginDropdown.open();">
        <!-- (mouseover)="closeAllDropdowns($event); loginDropdown.open();" -->
        <fa-icon [icon]="faUser" class="icon-link mr-4"></fa-icon> <!-- {{ profile.name }} -->
      </span>
      <div ngbDropdownMenu class="dropdown-container" aria-labelledby="login-link" id="logout-menu">
        <ng-container *ngIf="authService.userProfile$ | async as profile">
          <p><img class="user-avatar" src="{{ profile.picture }}"> {{ profile.name }}</p>
          <p><fa-icon [icon]="faMail" class="home-nav-icon mr-2"></fa-icon><u>{{ profile.email }}</u></p>
        </ng-container>
        <span class="logout-link" title="{{ 'LOGOUT' | translate }}" (click)="doLogout()">
          <fa-icon [icon]="faLogout" class="home-nav-icon mr-2"></fa-icon>
          <span>{{ 'LOGOUT' | translate }}</span>
        </span>
      </div>
    </div>

    <!-- LANGUAGE -->
    <div ngbDropdown class="d-inline-block" #languageDropdown="ngbDropdown">
      <span ngbDropdownToggle
        id="language-link" class="language-link"
        (click)="closeAllDropdowns($event); languageDropdown.open();"
        (focus)="closeAllDropdowns($event); languageDropdown.open();">
        <fa-icon [icon]="faLanguage" class="icon-link"></fa-icon></span>
      <div ngbDropdownMenu class="dropdown-container" aria-labelledby="language-link" >
        <div class="mt-0 mb-0">
          <div class="mb-2">{{ 'LANGUAGE_CHOOSE' | translate }}:</div>
          <div *ngFor="let languageId of translate.getLangs()">
            <button ngbDropdownItem (click)="switchLanguage(languageId)" class="pl-2">
              <div class="language-flag-container mr-2">
                <img src="{{ this.getLanguageFlag(languageId) }}" class="language-flag">
              </div>
              {{ this.getLanguageName(languageId) }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- SHOW HOME NAVIGATION IN THE CENTER -->
  <div class="d-inline-block" *ngIf="showHomeLink()">
    <a routerLink="dashboard">
      <fa-icon [icon]="faHome" class="home-nav-icon mr-4"></fa-icon>
    </a>
  </div>

  <button class="navbar-toggler navbar-toggler-right mt-1 mb-1" data-toggle="collapse" data-target="#navContent" type="button">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse navbar-content" id="navContent" data-toggle="collapse" data-target=".navbar-collapse">
    <hr class="mt-0 mb-0"/>
    <ul class="navbar-nav mr-auto">

      <li class="nav-item text-nowrap">
        <a class="nav-link" routerLink="dashboard" routerLinkActive="active">
          <fa-icon [icon]="faHome" class="icon-link ml-2"></fa-icon>{{ 'NAV_DASHBOARD' | translate }}
        </a>
      </li>

      <!-- TODO: Finally Remove Midas Component? -->
      <li class="nav-item text-nowrap" *ngIf="authService.loggedIn">
        <a class="nav-link" routerLink="midas" *ngIf="userPermissionsService.allowCalculateMidas">
          <fa-icon [icon]="faSlidersH" class="icon-link ml-2"></fa-icon>{{ 'NAV_MIDAS' | translate }}
        </a>
        <span class="nav-link" *ngIf="!userPermissionsService.allowCalculateMidas">
          <fa-icon [icon]="faSlidersH" class="icon-link ml-2"></fa-icon>{{ 'NAV_MIDAS' | translate }}<fa-icon [icon]="faLock" class="icon-link ml-2"></fa-icon>
        </span>
      </li>


      <li class="nav-item text-nowrap" *ngIf="authService.loggedIn">
        <a class="nav-link" routerLink="simulation" *ngIf="userPermissionsService.allowCalculateBacktest">
          <fa-icon [icon]="faChart" class="icon-link ml-2"></fa-icon>{{ 'NAV_SIMULATION' | translate }}
        </a>
        <span class="nav-link" *ngIf="!userPermissionsService.allowCalculateBacktest">
          <fa-icon [icon]="faChart" class="icon-link ml-2"></fa-icon>{{ 'NAV_SIMULATION' | translate }}<fa-icon [icon]="faLock" class="icon-link ml-2"></fa-icon>
        </span>

      </li>

      <li class="nav-item text-nowrap" *ngIf="authService.loggedIn">
        <a class="nav-link" routerLink="trading" *ngIf="userPermissionsService.allowReadTrades">
          <fa-icon [icon]="faHandHoldingUsd" class="icon-link ml-2"></fa-icon>{{ 'NAV_TRADING' | translate }}
        </a>
        <a class="nav-link" *ngIf="!userPermissionsService.allowReadTrades">
          <fa-icon [icon]="faHandHoldingUsd" class="icon-link ml-2"></fa-icon>{{ 'NAV_TRADING' | translate }}<fa-icon [icon]="faLock" class="icon-link ml-2"></fa-icon>
        </a>

      </li>

    </ul>
  </div>
</nav>
