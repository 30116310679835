<!-- CHECK FOR NEEDED PERMISSIONS -->
<div class="container mt-4" *ngIf="!permitted">
  <ngb-alert type="danger" [dismissible]="false" class="mb-4" style="text-align: center;">
    <span class="mt-3" [innerHTML]="'NOT_ALLOWED' | translate | keepHtml"></span>
  </ngb-alert>
</div>

<div class="container mb-4" *ngIf="permitted">

  <h6 class="pb-2">{{ 'SIMULATION_BACKTEST' | translate }}:</h6>

  <!-- See: https://material.angular.io/components/expansion/overview -->
  <mat-accordion>

    <!-- DATE RANGE -->
    <mat-expansion-panel> <!-- hideToggle -->
      <mat-expansion-panel-header>
        <mat-panel-title>{{ 'SIMULATION_PERIOD' | translate }}</mat-panel-title>
        <mat-panel-description *ngIf="dateRange!==null && dateRange!==undefined">{{ dateRange.dateRangeString }}</mat-panel-description>
        <mat-panel-description *ngIf="dateRange===null || dateRange===undefined">---</mat-panel-description>
      </mat-expansion-panel-header>
      <app-daterange (selectedRangeEvent)="setDateRange($event)"></app-daterange>
    </mat-expansion-panel>

    <!-- SCHEDULER -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>{{ 'SIMULATION_SCHEDULER' | translate }}</mat-panel-title>
        <mat-panel-description>{{ schedulerDescription }}</mat-panel-description>
      </mat-expansion-panel-header>
      <div class="ml-3">
        <div class="row">
          <mat-checkbox [(ngModel)]="onlyScheduled" (change)="setScheduler($event)">{{ 'SCHEDULER_ONLY_SCHEDULED' | translate }}</mat-checkbox>
        </div>
        <div class="row">
          <mat-checkbox class="mr-3" [disabled]="!onlyScheduled" [(ngModel)]="schedulerMorning" (change)="setScheduler($event)">{{ 'morgens' | translate }} (1|0)</mat-checkbox>
          <mat-checkbox class="mr-3" [disabled]="!onlyScheduled" [(ngModel)]="schedulerMid" (change)="setScheduler($event)">{{ 'mittags' | translate }} (2)</mat-checkbox>
          <mat-checkbox class="mr-3" [disabled]="!onlyScheduled" [(ngModel)]="schedulerEvening" (change)="setScheduler($event)">{{ 'abends' | translate }} (4|3)</mat-checkbox>
        </div>
      </div>
    </mat-expansion-panel>

    <!-- START VALUE -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>{{ 'SIMULATION_START_VALUE' | translate }}</mat-panel-title>
        <mat-panel-description>{{ startValue }}</mat-panel-description>
      </mat-expansion-panel-header>
      <form class="start-value-form">
        <mat-form-field class="start-value-form" appearance="fill">
          <mat-label [translate]="'SIMULATION_START_VALUE'"></mat-label>
          <input [(ngModel)]="startValue" [ngModelOptions]="{standalone: true}" type="number" min="0" max="10000000" (keypress)="numericOnly($event)" (change)="setStartValue($event)" matInput placeholder="10000" value="10000">
        </mat-form-field>
      </form>
    </mat-expansion-panel>

    <!-- PRESET (LEVERAGES AND THRESHOLDS) -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>{{ 'SIMULATION_PRESET' | translate }}</mat-panel-title>
        <mat-panel-description>{{ preset | translate }}</mat-panel-description> <!-- TODO -->
      </mat-expansion-panel-header>
      <app-threshold-leverage (selectedPresetEvent)="setPreset($event)"></app-threshold-leverage>
    </mat-expansion-panel>

    <!-- WEIGHTS -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>{{ 'MIDAS_WEIGHTS' | translate }}</mat-panel-title>
        <mat-panel-description *ngIf="weightsDefault" [translate]="'MIDAS_WEIGHTS_DEFAULT'"></mat-panel-description>
        <mat-panel-description *ngIf="!weightsDefault" [translate]="'SIMULATION_CLICK_TO_EXPAND'"></mat-panel-description>
      </mat-expansion-panel-header>
      <app-weights (selectedWeightsEvent)="setWeights($event)"></app-weights>
    </mat-expansion-panel>

  </mat-accordion>

  <div #results id="results"></div>

  <!-- 'START SIMULATION'-BUTTTON -->
  <div class="container pt-3 pb-3">
    <div class="align-center">
      <button type="button" class="btn btn-light" (click)="startSimulation();">{{ 'SIMULATION_START' | translate }}</button>
    </div>
  </div>

  <!-- BACKTEST RESULTS -->
  <div class="container mt-3" *ngIf="noDataAlert">
    <ngb-alert type="info" [dismissible]="true" (close)="noDataAlert=false" class="mb-4" style="text-align: center;">
      <span class="mt-3" [innerHTML]="'NO_DATA' | translate | keepHtml"></span>
    </ngb-alert>
  </div>

  <!-- BACKTEST RESULTS: CHARTS -->
  <div  class="container" *ngIf="backtestResults !==null && backtestResults.length > 0">
    <app-backtestchart (chartRenderedEvent)="chartsRendered=true" [backtestResult]=backtestResults></app-backtestchart>
  </div>

  <!-- BACKTEST RESULTS: PERFORMANCE -->
  <div class="container" *ngIf="backtestResults !==null && backtestResults.length > 0">
    <h6 class="pt-2">{{ 'PERFORMANCE' | translate }}</h6>
    <table class="performance-table">
      <thead>
        <tr>
          <th></th>
          <th>DAX</th>
          <th>{{ 'MIDAS' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ 'PERFORMANCE_FIRST_VALUE' | translate }}</td>
          <td>{{ daxPerformance.startValue.toFixed(2) }}</td>
          <td>{{ midasPerformance.startValue.toFixed(2) }}</td>
        </tr>
        <tr>
          <td>{{ 'PERFORMANCE_LAST_VALUE' | translate }}</td>
          <td>{{ daxPerformance.endValue.toFixed(2) }}</td>
          <td>{{ midasPerformance.endValue.toFixed(2) }}</td>
        </tr>
        <tr>
          <td>{{ 'PERFORMANCE_HIGHEST_VALUE' | translate }}</td>
          <td>{{ daxPerformance.highestValue.toFixed(2) }}</td>
          <td>{{ midasPerformance.highestValue.toFixed(2) }}</td>
        </tr>
        <tr>
          <td>{{ 'PERFORMANCE_LOWEST_VALUE' | translate }}</td>
          <td>{{ daxPerformance.lowestValue.toFixed(2) }}</td>
          <td>{{ midasPerformance.lowestValue.toFixed(2) }}</td>
        </tr>
        <tr>
          <td>{{ 'PERFORMANCE_HIGHEST_PERFORMANCE' | translate }}</td>
          <td>{{ daxPerformance.performanceMax | performance }}</td>
          <td>{{ midasPerformance.performanceMax | performance }}</td>
        </tr>
        <tr>
          <td>{{ 'PERFORMANCE_LOWEST_PERFORMANCE' | translate }}</td>
          <td>{{ daxPerformance.performanceMin | performance }}</td>
          <td>{{ midasPerformance.performanceMin | performance }}</td>
        </tr>

        <tr>
          <td>{{ 'PERFORMANCE_FINAL_PERFORMANCE' | translate }}</td>
          <td>{{ daxPerformance.performanceFinal | performance }}</td>
          <td><strong>{{ midasPerformance.performanceFinal | performance }}</strong></td>
        </tr>
      </tbody>
    </table>

    <!-- TODO -->

  </div>

  <!-- BACKTEST RESULTS: TABLE -->
  <div class="container" *ngIf="backtestResults !==null && backtestResults.length > 0">
    <h6 class="pt-4">{{ 'SIMULATION_DETAILS' | translate }}</h6>
    <!-- See: https://swimlane.gitbook.io/ngx-datatable/getting-started -->
    <!-- See: https://swimlane.gitbook.io/ngx-datatable/api/table/inputs -->
    <ngx-datatable #backtestResultsDatatable id="backtestResultsDatatable"
                  class = "bootstrap"
                  [selectionType] = "'single'"
                  [selected] = "selected"
                  [selectCheck]="singleSelectCheck"
                  [count] = "0"
                  [loadingIndicator] = "false"
                  [reorderable] = "false"
                  [swapColumns] = "false"
                  [rows] = 'this.backtestResults'
                  [limit] = "10"
                  [columnMode] = "'flex'"
                  [rowHeight] = "'auto'"
                  [footerHeight] = "'auto'"
                  [sortType] = "'single'"
                  [sorts] = "[{prop: 'snapshotTimestamp', dir: 'desc'}]">

      <ngx-datatable-column [flexGrow]="5" [resizeable]="false" prop="snapshotTimestamp" [sortable]="true">
        <ng-template ngx-datatable-header-template let-sort="sortFn" >
          <a class="datatable-header" (click)="startSorting(sort)" placement="right" container="body">{{ 'DATE_TIME' | translate }}</a>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>{{ value | date:'dd.MM.yyyy HH:mm' }}</ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [flexGrow]="2" [resizeable]="false" prop="midasValue" [sortable]="false">
        <ng-template ngx-datatable-header-template>{{ 'MIDAS' | translate }}</ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [flexGrow]="5" [resizeable]="false" prop="signal" [sortable]="false">
        <ng-template ngx-datatable-header-template>{{ 'SIGNAL' | translate }}</ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>{{ value | translate }}</ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [flexGrow]="3" [resizeable]="false" prop="leverString" [sortable]="false">
        <ng-template ngx-datatable-header-template>{{ 'LEVER' | translate }}</ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [flexGrow]="4" [resizeable]="false" prop="daxValue" [sortable]="false">
        <ng-template ngx-datatable-header-template>{{ 'DAX_LAST' | translate }}</ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>{{ value.toFixed(2) }}</ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column [flexGrow]="3" [resizeable]="false" prop="daxChangePercent" [sortable]="false">
        <ng-template ngx-datatable-header-template>{{ 'CHANGE' | translate }}</ng-template>
      </ngx-datatable-column>

      <!--
      <ngx-datatable-column [flexGrow]="4" [resizeable]="false" prop="daxResult" [sortable]="false">
        <ng-template ngx-datatable-header-template>{{ 'DAX_NORM' | translate }}</ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>{{ value.toFixed(2) }}</ng-template>
      </ngx-datatable-column>
      -->

      <ngx-datatable-column [flexGrow]="4" [resizeable]="false" prop="underDax" [sortable]="false">
        <ng-template ngx-datatable-header-template>{{ 'RESULT' | translate }}</ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template><strong>{{ value.toFixed(2) }}</strong></ng-template>
      </ngx-datatable-column>

      <ngx-datatable-footer >
        <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount"
          let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
          <!-- https://stackoverflow.com/questions/48121486/get-current-display-count-from-ngx-datatable -->
          <!-- https://github.com/swimlane/ngx-datatable/issues/739 -->
          <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
            [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="false"
            (change)="backtestResultsDatatable.onFooterPage($event)">
          </datatable-pager>
        </ng-template>
      </ngx-datatable-footer>

    </ngx-datatable>
  </div>

</div>
