import { SpinnerService } from '../spinner/spinner.service';
import { Component, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { Configuration } from 'src/i18n/i18n.config';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/auth/auth.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { faSignInAlt, faSignOutAlt, faGlobe, faHome, faChartLine, faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons';
import { faUser, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faLock, faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { UserPermissionsService } from 'src/app/auth/permissions.service';

// tslint:disable: max-line-length

@Component({
  selector: 'app-navigation',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavigationComponent implements AfterViewInit {

  @ViewChildren(NgbDropdown) dropdowns: QueryList<NgbDropdown>;
  dropdown: NgbDropdown;

  private router: Router;

  // Icons from font awesome
  faHome = faHome;
  faLogin = faSignInAlt;
  faLogout = faSignOutAlt;
  faLanguage = faGlobe;
  faChart = faChartLine;
  faUser = faUser;
  faMail = faEnvelope;
  faHandHoldingUsd = faHandHoldingUsd;
  faLock = faLock;
  faSlidersH = faSlidersH;

  constructor(router: Router, public translate: TranslateService, public authService: AuthService, public config: Configuration, public spinnerService: SpinnerService, public userPermissionsService: UserPermissionsService) {
    this.router = router;
  }

  ngAfterViewInit(): void {
    this.spinnerService.setMarginTop(document.querySelector('.navbar').clientHeight);
    console.log(document.querySelector('.navbar').clientHeight); // TODO
  }

  getLanguageName(languageId: string): string {
    let languageName: string = null;
    this.config.providedLanguages.forEach(language => {
      if (languageId === language.id) {
        languageName = language.name;
      }
    });
    return languageName;
  }

  getLanguageFlag(languageId: string): string {
    let languageFlag: string = null;
    this.config.providedLanguages.forEach(language => {
      if (languageId === language.id) {
        languageFlag = language.flag;
      }
    });
    return languageFlag;
  }

  switchLanguage(languageId: string): void {
    this.translate.use(languageId);
    this.config.setUserLanguage(languageId);
  }

  doLogin(): void {
    this.authService.login();
  }

  doLogout(): void {
    this.authService.logout();
  }

  closeAllDropdowns($event: any): void {
    this.dropdowns.forEach(dropdown => {
      dropdown.close();
    });
  }

  showHomeLink(): boolean {
    return (this.router.url !== '/dashboard');
  }

}
