import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class LocalRuntimeEnvironment {
  public getValueFor(key: string): any {
    if (environment) {
      const value = environment[key];
      if (value) {
        return value;
      }
    }
    throw Error(`Environment variable ${key} is not defined`);
  }
}
