import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable, throwError } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';

// tslint:disable: max-line-length

@Injectable({ providedIn: 'root' })
export class InterceptorService implements HttpInterceptor {

  constructor(public authService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService.loggedIn) {
      return this.authService.getTokenSilently$().pipe(
        mergeMap(token => {
          const tokenReq = req.clone({
            setHeaders: { Authorization: `Bearer ${token}` }
          });
          // console.log('HttpInterceptor: Add Authorization header with Bearer token:', tokenReq.headers.get('Authorization')); // DEBUG ONLY
          return next.handle(tokenReq);
        }),
        catchError(err => throwError(err))
      );
    } else {
      // console.log('HttpInterceptor: Passthrough'); // DEBUG ONLY
      return next.handle(req);
    }
  }

}
