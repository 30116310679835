<div class="d-flex justify-content-center mb-5">
  <img mat-card-image class="img" src="/assets/images/tradstrat.png" width="360px" height="112px">
</div>

<!-- MAIN CONTENT WHEN LOGGED OUT -->
<div class="container" *ngIf="!authService.loggedIn">
  <div class="login-message">
    <p *ngIf="authService.loggedOut">{{ 'LOGOUT_MESSAGE' | translate }}</p>
    <p><span class="login-link" title="{{ 'LOGIN' | translate }}" (click)="authService.login()" (focus)="authService.login()">{{ 'LOGIN_MESSAGE' | translate }}</span></p>
  </div>
</div>

<!-- CHECK FOR EMAIL VERIFICATION -->
<div class="container" *ngIf="missingEmailVerification">
  <ngb-alert type="warning" [dismissible]="false" class="mb-4" style="text-align: center;">
    <span class="mt-3" [innerHTML]="'MISSING_EMAIL_VERIFICATION' | translate | keepHtml"></span><br><br>
    <span *ngIf="!verificationEmailRequested" style="cursor: pointer;" (click)="requestVerificationMail();">
      {{ 'MISSING_EMAIL_REREQUEST' | translate }}
    </span>
    <span *ngIf="verificationEmailRequested">
      <small>{{ 'MISSING_EMAIL_REREQUESTED' | translate }}</small>
    </span>
  </ngb-alert>
</div>

<!-- CHECK FOR NEEDED PERMISSIONS -->
<div class="container" *ngIf="!missingEmailVerification && missingPermissions">
  <ngb-alert type="warning" (close)="missingPermissions=false;" class="mb-4" style="text-align: center;">
    <span class="mt-3" [innerHTML]="'MISSING_PERMISSIONS' | translate | keepHtml"></span>
  </ngb-alert>
</div>

<div class="container">
  <hr/>
</div>

<!-- MAIN CONTENT WHEN LOGGED IN AND PERMISSIONS ARE OK -->
<div class="container">

  <div class="d-flex flex-wrap justify-content-between" *ngIf="apiService.latestStockSnapshot$ | async as result">

    <div class="pl-0 pr-2 pb-2 pt-2">

      <h6>{{ 'SNAPSHOT_LATEST' | translate }}:</h6>
      {{ 'DATE_TIME' | translate }}: {{ result.timestamp | date:'dd.MM.yyyy, HH:mm:ss' }}<br>
      DAX: {{ result.daxLastValue | replaceDotByComma }}
      <small>(<span [innerHTML]="calcDaxChange() | keepHtml"></span> /
      <span [innerHTML]="calcDaxChangePercent() | keepHtml"></span>)</small><br>
      Gold (Euro/oz.tr.): {{ result.goldLastValue | replaceDotByComma }}
      <small>(<span [innerHTML]="calcGoldChange() | keepHtml"></span> /
      <span [innerHTML]="calcGoldChangePercent() | keepHtml"></span>)</small><br>
      Euro/USD: {{ result.eurLastValue | replaceDotByComma }}
      <small>(<span [innerHTML]="calcEurUsdChange() | keepHtml"></span> /
      <span [innerHTML]="calcEurUsdChangePercent() | keepHtml"></span>)</small><br>
      VDAX New: {{ result.vdaxValue | replaceDotByComma }}<br>
      CBOE Volatility Index: {{ result.vixValue | replaceDotByComma }}<br>
      US 2 Year Treasury Yield: {{ result.usbonds2yrValue | replaceDotByComma }}<br>
      <!-- US 5 Year Treasury Yield: {{ result.usbonds5yrValue | replaceDotByComma }}<br> -->
      US 10 Year Treasury Yield: {{ result.usbonds10yrValue | replaceDotByComma }}<br>
      <!-- <pre><code style="font-size: smaller !important;">{{ this.latestStockSnapshot | json }}</code></pre> -->
    </div>

    <div class="pl-0 pr-2 pb-2 pt-2" *ngIf="apiService.latestMidasResult$ | async as result">
      <h6>{{ 'MIDAS_LATEST' | translate }}:</h6>
      {{ 'DATE_TIME' | translate }}: {{ result.snapshotTimestamp | date:'dd.MM.yyyy, HH:mm:ss' }}<br>
      {{ 'MIDAS' | translate }}: <strong>{{ result.result | replaceDotByComma }}</strong><br>
      {{ 'LEVER' | translate }}: <strong>{{ result.leverString | replaceDotByComma }}</strong><br>
      {{ 'SIGNAL' | translate }}: <strong>{{ result.signal | translate }}</strong><br>
      <!-- <pre><code style="font-size: smaller !important;">{{ this.latestMidasResult | json }}</code></pre> -->
    </div>

    <div class="pl-0 pr-0 pb-2 pt-2 vertical-center" *ngIf="apiService.latestMidasResult$ | async as result">
      <div class="text-right">
        <a routerLink="/midas" class="nav-link"><fa-icon [icon]="faNavigate" class="nav-icon ml-0 mr-4"></fa-icon></a>
      </div>
    </div>
  </div>
</div>

<div class="container" *ngIf="apiService.latestStockSnapshot$ | async as result">
  <hr/>
</div>

<app-stockchart></app-stockchart>

<!-- DEVELOPMENT/DEBUG CONTENT -->
<!--
<div class="container" *ngIf="authService.loggedIn && !authService.loggedOut">
  <hr class="mt-5"/>
  <h6>Make Snapshot request without database:</h6>
  <span style="font-size: smaller !important;">{{ userPermissionsService.allowReadStock }}</span>
  <h6>Make Midas request without database:</h6>
  <span style="font-size: smaller !important;">{{ userPermissionsService.allowCalculateMidas }}</span>
  <h6>Read Snapshot from database:</h6>
  <span style="font-size: smaller !important;">{{ userPermissionsService.allowCalculateBacktest }}</span>
  <h6>Read Midas from database:</h6>
  <span style="font-size: smaller !important;">{{ userPermissionsService.allowReadTrades }}</span>
  <h6>Write Snapshot to database:</h6>
  <span style="font-size: smaller !important;">{{ userPermissionsService.allowWriteStock }}</span>
  <h6>Write Midas to database:</h6>
  <span style="font-size: smaller !important;">{{ userPermissionsService.allowPutTrades }}</span>
  <h6>Create and delete tables:</h6>
  <span style="font-size: smaller !important;">{{ userPermissionsService.allowCrudDatabase }}</span>
  <h6>E-Mail is verified:</h6>
  <span style="font-size: smaller !important;">{{ userPermissionsService.emailVerified }}</span>
  <hr class="mt-5"/>
  <h6>User Profile:</h6>
  <pre *ngIf="authService.userProfile$ | async as profile"><code style="font-size: smaller !important;">{{ profile | json }}</code></pre>
  <hr/>
</div>
-->
