<!-- See: https://apexcharts.com/angular-chart-demos/line-charts/syncing-charts/ -->

<div *ngIf="chartsInitialized" class="container" >
  <div class="row">
    <div class="col col-12 pr-0">
      <h6>{{ 'OVERVIEW_90_DAYS' | translate }}:</h6>
    </div>
  </div>
</div>

<div *ngIf="chartsInitialized" class="container">

  <ngb-accordion #acc="ngbAccordion">

    <ngb-panel id="toggle-dax">
      <ng-template ngbPanelHeader let-opened="opened" >
        <div id="expand-link" (click)="acc.toggle('toggle-dax')">
          <fa-icon [icon]="faExpand" class="nav-icon ml-1 mr-2"></fa-icon>DAX
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="row">
          <div class="col col-12">
            <div id="collapse-link" (click)="acc.toggle('toggle-dax')">
              <fa-icon [icon]="faCollapse" class="nav-icon ml-1 mr-2"></fa-icon>DAX
            </div>
            <apx-chart id="dax"
              [series]="daxChartOptions.series"
              [chart]="daxChartOptions.chart"
              [colors]="daxChartOptions.colors"
              [xaxis]="daxChartOptions.xaxis"
              [yaxis]="daxChartOptions.yaxis"
              [stroke]="daxChartOptions.stroke"
              [dataLabels]="commonOptions.dataLabels"
              [markers]="commonOptions.markers"
              [grid]="commonOptions.grid"
              [tooltip]="commonOptions.tooltip"
              [legend]="legend"
            ></apx-chart>
          </div>
        </div>
      </ng-template>
    </ngb-panel>

    <ngb-panel id="toggle-midas">
      <ng-template ngbPanelHeader let-opened="opened" >
        <div id="expand-link" (click)="acc.toggle('toggle-midas')">
          <fa-icon [icon]="faExpand" class="nav-icon ml-1 mr-2"></fa-icon>Midas / Signal
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="row">
          <div class="col col-12">
            <div id="collapse-link" (click)="acc.toggle('toggle-midas')">
              <fa-icon [icon]="faCollapse" class="nav-icon ml-1 mr-2"></fa-icon>Midas / Signal
            </div>
            <apx-chart id="midas"
              [series]="midasChartOptions.series"
              [chart]="midasChartOptions.chart"
              [colors]="midasChartOptions.colors"
              [xaxis]="midasChartOptions.xaxis"
              [yaxis]="midasChartOptions.yaxis"
              [stroke]="midasChartOptions.stroke"
              [fill]="midasChartOptions.fill"
              [dataLabels]="commonOptions.dataLabels"
              [markers]="commonOptions.markers"
              [grid]="commonOptions.grid"
              [tooltip]="commonOptions.tooltip"
              [legend]="legend"
              [annotations]="signalAnnotations"
            ></apx-chart>
          </div>
        </div>
      </ng-template>
    </ngb-panel>

    <ngb-panel id="toggle-gold">
      <ng-template ngbPanelHeader let-opened="opened" >
        <div id="expand-link" (click)="acc.toggle('toggle-gold')">
          <fa-icon [icon]="faExpand" class="nav-icon ml-1 mr-2"></fa-icon>Gold
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="row">
          <div class="col col-12">
            <div id="collapse-link" (click)="acc.toggle('toggle-gold')">
              <fa-icon [icon]="faCollapse" class="nav-icon ml-1 mr-2"></fa-icon>Gold
            </div>
            <apx-chart id="gold"
              [series]="goldChartOptions.series"
              [chart]="goldChartOptions.chart"
              [colors]="goldChartOptions.colors"
              [xaxis]="goldChartOptions.xaxis"
              [yaxis]="goldChartOptions.yaxis"
              [stroke]="commonOptions.stroke"
              [fill]="goldChartOptions.fill"
              [dataLabels]="commonOptions.dataLabels"
              [markers]="commonOptions.markers"
              [grid]="commonOptions.grid"
              [tooltip]="goldChartOptions.tooltip"
              [legend]="legend"
            ></apx-chart>
          </div>
        </div>
      </ng-template>
    </ngb-panel>

    <ngb-panel id="toggle-eur">
      <ng-template ngbPanelHeader let-opened="opened" >
        <div id="expand-link" (click)="acc.toggle('toggle-eur')">
          <fa-icon [icon]="faExpand" class="nav-icon ml-1 mr-2"></fa-icon>EUR / USD
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="row">
          <div class="col col-12">
            <div id="collapse-link" (click)="acc.toggle('toggle-eur')">
              <fa-icon [icon]="faCollapse" class="nav-icon ml-1 mr-2"></fa-icon>EUR / USD
            </div>
            <apx-chart id="eur"
              [series]="eurChartOptions.series"
              [chart]="eurChartOptions.chart"
              [colors]="eurChartOptions.colors"
              [xaxis]="eurChartOptions.xaxis"
              [yaxis]="eurChartOptions.yaxis"
              [stroke]="commonOptions.stroke"
              [fill]="eurChartOptions.fill"
              [dataLabels]="commonOptions.dataLabels"
              [markers]="commonOptions.markers"
              [grid]="commonOptions.grid"
              [tooltip]="eurChartOptions.tooltip"
              [legend]="legend"
            ></apx-chart>
          </div>
        </div>
      </ng-template>
    </ngb-panel>

    <ngb-panel id="toggle-volatility">
      <ng-template ngbPanelHeader let-opened="opened" >
        <div id="expand-link" (click)="acc.toggle('toggle-volatility')">
          <fa-icon [icon]="faExpand" class="nav-icon ml-1 mr-2"></fa-icon>Volatility
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="row">
          <div class="col col-12">
            <div id="collapse-link" (click)="acc.toggle('toggle-volatility')">
              <fa-icon [icon]="faCollapse" class="nav-icon ml-1 mr-2"></fa-icon>Volatility
            </div>
            <apx-chart id="volatility"
              [series]="volatilityChartOptions.series"
              [chart]="volatilityChartOptions.chart"
              [colors]="volatilityChartOptions.colors"
              [xaxis]="volatilityChartOptions.xaxis"
              [yaxis]="volatilityChartOptions.yaxis"
              [stroke]="commonOptions.stroke"
              [fill]="volatilityChartOptions.fill"
              [dataLabels]="commonOptions.dataLabels"
              [markers]="commonOptions.markers"
              [grid]="commonOptions.grid"
              [tooltip]="commonOptions.tooltip"
              [legend]="legend"
            ></apx-chart>
          </div>
        </div>
      </ng-template>
    </ngb-panel>

    <ngb-panel id="toggle-usbonds" title="US Bonds">
      <ng-template ngbPanelHeader let-opened="opened" >
        <div id="expand-link" (click)="acc.toggle('toggle-usbonds')">
          <fa-icon [icon]="faExpand" class="nav-icon ml-1 mr-2"></fa-icon>US Bonds
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="row">
          <div class="col col-12">
            <div id="collapse-link" (click)="acc.toggle('toggle-usbonds')">
              <fa-icon [icon]="faCollapse" class="nav-icon ml-1 mr-2"></fa-icon>US Bonds
            </div>
            <apx-chart id="usbonds"
              [series]="usBondsChartOptions.series"
              [chart]="usBondsChartOptions.chart"
              [colors]="usBondsChartOptions.colors"
              [xaxis]="usBondsChartOptions.xaxis"
              [yaxis]="usBondsChartOptions.yaxis"
              [stroke]="commonOptions.stroke"
              [fill]="usBondsChartOptions.fill"
              [dataLabels]="commonOptions.dataLabels"
              [markers]="commonOptions.markers"
              [grid]="commonOptions.grid"
              [tooltip]="commonOptions.tooltip"
              [legend]="legend"
            ></apx-chart>
          </div>
        </div>
      </ng-template>
    </ngb-panel>

  </ngb-accordion>

</div>
