import { PerformanceValuePipe } from './shared/pipes/performance-value-pipe';
import { FooterService } from './shared/components/footer/footer.service';
import { MidasComponent } from './midas/midas.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { I18nModule } from '../i18n/i18n.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSliderModule } from '@angular/material/slider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule, NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { RouteReuseStrategy } from '@angular/router';
import { CustomReuseStrategy } from './app-routing-reuse-strategy';
import { KeepHtmlPipe } from './shared/pipes/keep-html.pipe';
import { EncodeURIPipe } from './shared/pipes/encode-uri.pipe';
import { AuthService } from './auth/auth.service';
import { AuthGuard } from './auth/auth.guard';
import { InterceptorService } from './auth/interceptor.service';
import { AppComponent } from './app.component';
import { NavigationComponent } from './shared/components/nav/nav.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { Auth0CallbackComponent } from './auth/callback/auth-callback.component';
import { Auth0LogoutComponent } from './auth/callback/auth-logout.component';
import { WINDOW_PROVIDERS } from './shared/services/window-ref';
import { SpinnerComponent } from './shared/components/spinner/spinner.component';
import { UserPermissionsService } from './auth/permissions.service';
import { SpinnerService } from './shared/components/spinner/spinner.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { VerificationMailService } from './auth/verifcation-mail.service';
import { ReplaceDotByComma } from './shared/pipes/replace-dot-comma.pipe';
import { StockchartComponent } from './shared/components/stockchart/stockchart.component';
import { NgApexchartsModule } from "ng-apexcharts";
import { SimulationComponent } from './simulation/simulation.component';
import { DaterangeComponent } from './shared/components/daterange/daterange.component';
import { WeightsComponent } from './shared/components/weights/weights.component';
import { ThresholdLeverageComponent } from './shared/components/threshold-leverage/threshold-leverage.component';
import { BacktestchartComponent } from './shared/components/backtestchart/backtestchart.component';
import { TradingComponent } from './trading/trading.component';
// import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    FooterComponent,
    DashboardComponent,
    MidasComponent,
    SpinnerComponent,
    Auth0CallbackComponent,
    Auth0LogoutComponent,
    KeepHtmlPipe,
    EncodeURIPipe,
    PerformanceValuePipe,
    ReplaceDotByComma,
    StockchartComponent,
    SimulationComponent,
    DaterangeComponent,
    WeightsComponent,
    ThresholdLeverageComponent,
    BacktestchartComponent,
    TradingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AppRoutingModule,
    I18nModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSliderModule,
    MatCheckboxModule,
    MatSelectModule,
    BrowserAnimationsModule,
    NgbModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgxDatatableModule,
    NgApexchartsModule,
    NgbAccordionModule
    // NgxMaterialTimepickerModule
  ],
  providers: [
    CookieService,
    AuthService,
    AuthGuard,
    UserPermissionsService,
    SpinnerService,
    FooterService,
    VerificationMailService,
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
    WINDOW_PROVIDERS
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class AppModule { }
