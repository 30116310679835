import { Leverages } from "./Leverages";
import { Thresholds } from "./Thresholds";
import { Weights } from "./Weights";

export class MidasProperties {

  daxSmaIncludeNonClosed: boolean;
  weights : Weights;
  thresholds : Thresholds;
  leverages : Leverages;

}
