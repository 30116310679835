<div class="container mb-4" *ngIf="midasResults">

  <h6 class="head-with-right-content">{{ 'MIDAS_HISTORY' | translate }}:</h6>

  <!-- Filter scheduler -->
  <div ngbDropdown class="d-inline-block float-right pointer" #schedulerDropdown="ngbDropdown">
    <span ngbDropdownToggle
      id="filter-scheduler">
      {{ 'FILTER_SCHEDULER' | translate }} </span> <!-- schedulerDropdown.open(); -->
    <div ngbDropdownMenu class="dropdown-container pb-0" aria-labelledby="filter-scheduler" >
      <div class="mt-0 mb-0"> <!-- btn-group-vertical -->
        <div *ngFor="let schedulerId of this.schedulerIds" > <!-- btn-group-toggle -->
          <label ngbButtonLabel class="check-container mt-0 pt-0 mb-3 pb-0">
            <input type="checkbox" ngbButton
                [(ngModel)]="this.selectedSchedulersMap[schedulerId]"
                (change)="filterScheduler(schedulerId)">
                <span class="checkmark"></span>
                <span class="ml-3 text-nowrap">Scheduler {{ schedulerId }}</span>
          </label>
        </div>
      </div>
    </div>
  </div>

  <!-- See: https://swimlane.gitbook.io/ngx-datatable/getting-started -->
  <ngx-datatable #midasResultsDatatable
                class = "bootstrap"
                [selectionType] = "'single'"
                [selected] = "selected"
                [selectCheck]="singleSelectCheck"
                [count] = "0"
                [loadingIndicator] = "false"
                [reorderable] = "false"
                [swapColumns] = "false"
                [rows] = 'this.filteredMidasResults'
                [limit] = "6"
                [columnMode] = "'flex'"
                [rowHeight] = "'auto'"
                [footerHeight] = "'auto'"
                [sortType] = "'single'"
                [sorts] = "[{prop: 'snapshotTimestamp', dir: 'desc'}]"
                (activate) = 'onTableRowClick($event)'> <!-- [selected] = "selected" -->
    <ngx-datatable-column [flexGrow]="6" [resizeable]="false" prop="snapshotTimestamp" [sortable]="true">
      <ng-template let-sort="sortFn" ngx-datatable-header-template>
        <a class="datatable-header larger-header" (click)="startSorting(sort)" placement="right" container="body">
          {{ 'DATE_TIME' | translate }}
        </a>
      </ng-template>
      <ng-template let-value="value" let-sort="sortFn" ngx-datatable-cell-template>
        {{ value | date:'dd.MM.yy, HH:mm' }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [flexGrow]="3" [resizeable]="false" prop="result" [sortable]="false">
      <ng-template ngx-datatable-header-template>
        <span class="larger-header">{{ 'MIDAS' | translate }}</span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [flexGrow]="3" [resizeable]="false" prop="leverString" [sortable]="false">
      <ng-template ngx-datatable-header-template>
        <span class="larger-header">{{ 'LEVER' | translate }}</span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column [flexGrow]="5" [resizeable]="false" prop="signal" [sortable]="false">
      <ng-template ngx-datatable-header-template>
        <span class="larger-header">{{ 'SIGNAL' | translate }}</span>
      </ng-template>
      <ng-template let-value="value" let-sort="sortFn" ngx-datatable-cell-template>
        {{ value | translate }}
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-footer >
      <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount"
        let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
        <!-- https://stackoverflow.com/questions/48121486/get-current-display-count-from-ngx-datatable -->
        <!-- https://github.com/swimlane/ngx-datatable/issues/739 -->
        <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
          [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="false"
          (change)="midasResultsDatatable.onFooterPage($event)">
        </datatable-pager>
      </ng-template>
    </ngx-datatable-footer>

  </ngx-datatable>

</div>

<!-- TODO -->

<div class="container mb-4" *ngIf="midasResults">
  <button class="float-right" type="button" (click)="exportToCsv(); $event.preventDefault()">{{ 'EXPORT_CSV' | translate }}</button>
</div>

<div class="container mb-4" *ngIf="selectedMidasResult">

  <h6>{{ 'MIDAS_DETAILS' | translate }}:</h6>

  <!-- TODO -->

  <pre><code style="font-size: smaller !important;">{{ selectedMidasResult | json }}</code></pre>

</div>
