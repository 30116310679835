import { Router } from '@angular/router';
import { SpinnerService } from './../../shared/components/spinner/spinner.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: [
    './auth-callback.component.scss',
    './../../shared/styles/tradstrat.scss',
    './../../shared/styles/globalColours.scss'
  ]
})
export class Auth0CallbackComponent implements OnInit {

  private router: Router;
  private spinnerService: SpinnerService;
  errorOccurred = false;

  constructor(spinnerService: SpinnerService, public authService: AuthService, router: Router) {
    this.router = router;
    this.spinnerService = spinnerService;
  }

  ngOnInit(): void {
    sessionStorage.setItem('redirectComplete', 'true');
    this.spinnerService.startSpin();

    this.authService.loginError$.subscribe((error: any) => {
      this.spinnerService.stopSpin();
      this.errorOccurred = true;
    });

  }

  retryLogin(): void {
    this.spinnerService.startSpin();
    this.authService.login();
  }

  abortLogin(): void {
    this.router.navigate(['/']);
  }


}
