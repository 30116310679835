import { FooterService } from './footer.service';
import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { FooterContent } from './FooterContent';
import { TradStratApiService } from 'src/app/tradstrat-api/api.service';

// tslint:disable: use-lifecycle-interface

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  content: FooterContent;
  apiService: TradStratApiService;
  service: FooterService;

  constructor(tradStratApiService: TradStratApiService, private footerService: FooterService) {
    this.apiService = tradStratApiService;
    this.service = footerService;
  }

  ngOnInit(): void {

    this.service.footerContentChanged.subscribe((content: FooterContent) => {
      this.onFooterContentChanged(content);
    });
    this.apiService.health.subscribe(() => {
      this.updateFooter();
    });
    this.apiService.apiVersion.subscribe(() => {
      this.updateFooter();
    });
  }

  getAppVersion(): string {
    return environment.appVersion;
  }

  onFooterContentChanged(content: FooterContent): void {
    this.content = content;
  }

  updateFooter(): void {
    this.footerService.setContent({
      apiVersion: this.apiService.apiVersion.getValue(),
      apiHealth: this.apiService.health.getValue(),
    });
  }

}
