import { environment } from './../environments/environment.prod';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';

  // tslint:disable: max-line-length

@Injectable({ providedIn: 'root' })
export class Configuration {

  /* Language Settings */
  public providedLanguages = [
    { id: 'de', name: 'Deutsch', flag: '/assets/i18n/de.png' },
    { id: 'en', name: 'English', flag: '/assets/i18n/en.png' }
  ];
  public defaultLanguage = 'de';

  constructor(public cookieService: CookieService, public translate: TranslateService) {
  }

  setUserLanguage(languageId: string): void {
    if (localStorage) {
      localStorage.setItem('userLanguage', languageId);
    }
    this.cookieService.set('userLanguage', languageId);
  }

  getUserLanguage(): string {
    let languageId = this.defaultLanguage;
    const languages: string[] = [];
    this.providedLanguages.forEach(language => {
      languages.push(language.id);
    });
    const providedLanguages = new RegExp(languages.toString().replace(/,/g, '|'), 'gi');
    if (localStorage && localStorage.getItem('userLanguage')) { // Try to read Local Storage
      languageId = localStorage.getItem('userLanguage');
    }
    if (!languageId && this.cookieService.get('userLanguage')) { // Try to read Cookie Storage
      languageId = this.cookieService.get('userLanguage');
    }
    if (!languageId && this.translate.getBrowserLang() && environment.production) {
      languageId = this.translate.getBrowserLang(); // Try to read Browser Language
    }
    if (!languageId.match(providedLanguages)) { // Use default Language if all before failed
      languageId = this.defaultLanguage;
    }
    return languageId;
  }

}
