import { Summary } from './Summary';
import { BacktestOptions } from './../tradstrat-api/model/request/BacktestOptions';
import { Preset } from './../shared/components/threshold-leverage/Preset';
import { Thresholds } from './../tradstrat-api/model/request/Thresholds';
import { MidasOptions } from './../tradstrat-api/model/request/MidasOptions';
import { BacktestResult } from './../tradstrat-api/model/BacktestResult';
import { DateRange } from './../shared/components/daterange/DateRange';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Weights } from '../tradstrat-api/model/request/Weights';
import { TranslateService } from '@ngx-translate/core';
import { Leverages } from '../tradstrat-api/model/request/Leverages';
import { PresetProvider } from '../shared/components/threshold-leverage/PresetProvider';
import { MidasProperties } from '../tradstrat-api/model/request/MidasProperties';
import { AuthService } from '../auth/auth.service';
import { UserPermissionsService } from '../auth/permissions.service';
import { TradStratApiService } from '../tradstrat-api/api.service';
import { SpinnerService } from '../shared/components/spinner/spinner.service';

@Component({
  selector: 'app-simulation',
  templateUrl: './simulation.component.html',
  styleUrls: ['./simulation.component.scss']
})
export class SimulationComponent implements OnInit {

  @ViewChild('results') resultsRef: ElementRef;

  private apiService: TradStratApiService;
  private spinnerService: SpinnerService;
  private permissionServive: UserPermissionsService;
  permitted: boolean = false;
  noDataAlert: boolean = false;

  // SETTINGS / REQUEST
  dateRange: DateRange; // Date Range
  weights: Weights; // Weights
  weightsDefault: boolean; // Frontend only
  onlyScheduled: boolean = true; // Scheduler
  schedulerPattern: string = "1|0,2,4|3"; // Scheduler
  schedulerMorning: boolean = true; // Frontend only
  schedulerMid: boolean = true; // Frontend only
  schedulerEvening: boolean = true; // Frontend only
  schedulerDescription = ""; // Frontend only
  startValue: number = 10000; // Start value

  daxSmaIncludeNonClosed = true; // Include non-closed DAX values in SMA calculation // TODO
  thresholds: Thresholds; // Thresholds
  leverages: Leverages; // Leverages
  preset: string; // Preset (Frontend only)

  // RESPONSE
  backtestResults: BacktestResult[];

  // EVALUATION
  daxPerformance: Summary;
  midasPerformance: Summary;

  // Table
  selected: any[] = [];
  chartsRendered: boolean = false;
  tableRendered: boolean = false;
  scrolled: boolean = true;

  constructor(private ref: ChangeDetectorRef, public translate: TranslateService, public authService: AuthService, userPermissionsService: UserPermissionsService, tradStratApiService: TradStratApiService, spinnerService: SpinnerService) {
    const preset: Preset = PresetProvider.getPreset("PRESET_DEFAULT");
    this.thresholds = preset.thresholds;
    this.leverages = preset.leverages;
    this.preset = preset.preset;
    this.apiService = tradStratApiService;
    this.spinnerService = spinnerService;
    this.permissionServive = userPermissionsService;
    this.apiService.backtestResults$.subscribe((result: BacktestResult[]) => {
      this.backtestResults = result;
      this.spinnerService.stopSpin();
      this.obtainedResponse();
    });
  }

  ngAfterContentChecked() {
    this.ref.detectChanges(); // See: https://www.codegrepper.com/code-examples/javascript/can+i+ignore+expressionchangedafterithasbeencheckederror
  }

  ngOnInit(): void {
    this.permissionServive.allowCalculateBacktest$.subscribe((allowed: boolean) => {
      if (allowed) { // Granted permission, allow backtest
        this.initContent();
        this.permitted = true;
      }
    });
  }

  ngAfterViewChecked() {
    if (!this.scrolled) {
      // this.resultsRef.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start'});
      this.resultsRef.nativeElement.scrollIntoView();
      window.scrollBy(0, 10); // Adjust to hide button
      setTimeout(() => {
        this.scrolled = true;
      }, 500);
    }
  }

  initContent(): void {
    this.setDateRange(null);
    this.weights = new Weights();
    this.weightsDefault = true;
    this.setScheduler(null);
  }

  setDateRange(dateRange: DateRange): void {
    if (dateRange !== null) {
      this.dateRange = new DateRange(dateRange.getFromDate(), dateRange.getToDate());
      this.createRequest();
    } else {
      this.dateRange = null;
    }
  }

  setScheduler(event?: any): void {
    this.schedulerPattern = "";
    let details: string = ""
    if (this.onlyScheduled) {
      if (this.schedulerMorning) {
        this.schedulerPattern += "1|0"
        details += this.translate.instant('morgens');
      }
      if (this.schedulerMid) {
        if (this.schedulerPattern.length > 0) { this.schedulerPattern += ','; details += ', ' }
        this.schedulerPattern += "2"
        details += this.translate.instant('mittags');
      }
      if (this.schedulerEvening) {
        if (this.schedulerPattern.length > 0) { this.schedulerPattern += ','; details += ', ' }
        this.schedulerPattern += "4|3"
        details += this.translate.instant('abends');
      }
      if (this.schedulerPattern.length == 0) {
        this.schedulerPattern = null;
        this.schedulerDescription = this.translate.instant('SCHEDULER_ONLY_SCHEDULED');
      } else {
        // this.schedulerDescription = this.translate.instant('SCHEDULER_ONLY_SCHEDULED') + " (" + details + ")";
        this.schedulerDescription = details;
      }
    } else {
      this.schedulerPattern = null;
      this.schedulerDescription = this.translate.instant('SCHEDULER_UNDEFINED');
    }
  }

  setStartValue(event?: any): void {}

  numericOnly(event: KeyboardEvent): boolean {
    const pattern = /^([0-9])$/;
    return pattern.test(event.key);
  }

  setPreset(preset: Preset): void {
    this.thresholds = preset.thresholds;
    this.leverages = preset.leverages;
    this.preset = preset.preset;
  }

  setWeights(weights: Weights): void {
    this.weights = weights;
    this.weightsDefault = weights.isAllDefault();
  }

  private createRequest(): BacktestOptions {
    if (this.dateRange === null) {
      console.error("dateRange is NULL -> quit"); // TODO: Extend Validation
      return null;
    }
    const backtestOptions = new BacktestOptions();
    backtestOptions.startValue = this.startValue;
    backtestOptions.midasOptions = new MidasOptions();
    backtestOptions.midasOptions.onlyScheduled = this.onlyScheduled;
    backtestOptions.midasOptions.schedulerPattern = this.schedulerPattern;
    backtestOptions.midasOptions.timestamp = null;
    backtestOptions.midasOptions.minTimestamp = this.dateRange.fromTimeStamp;
    backtestOptions.midasOptions.maxTimestamp = this.dateRange.toTimeStamp;
    backtestOptions.midasOptions.midasProperties = new MidasProperties();
    backtestOptions.midasOptions.midasProperties.daxSmaIncludeNonClosed = this.daxSmaIncludeNonClosed;
    backtestOptions.midasOptions.midasProperties.weights = this.weights;
    backtestOptions.midasOptions.midasProperties.thresholds = this.thresholds;
    backtestOptions.midasOptions.midasProperties.leverages = this.leverages;
    return backtestOptions;
  }

  public startSimulation(): void {
    const backtestOptions = this.createRequest();
    if (backtestOptions === null) {
      console.error("Request body can't be created, backtestOptions is NULL"); // TODO: Extend Validation
      return;
    }
    this.noDataAlert = false;
    this.spinnerService.startSpin();
    this.apiService.getBacktest(backtestOptions);
  }

  startSorting(sort: () => any): any {
    return sort();
  }

  private obtainedResponse(): void {
    this.chartsRendered = false;
    this.scrolled = false;
    if (this.backtestResults !== null) {
      if (this.backtestResults.length === 0) {
        this.noDataAlert = true;
      }
      this.daxPerformance = new Summary();
      this.midasPerformance = new Summary()
      if (this.backtestResults.length > 0) {
        this.daxPerformance.firstDate = this.backtestResults[0].snapshotTimestamp;
        this.daxPerformance.lastDate = this.backtestResults[this.backtestResults.length - 1].snapshotTimestamp;
        this.daxPerformance.startValue = this.backtestResults[0].daxValue;
        this.daxPerformance.endValue = this.backtestResults[this.backtestResults.length - 1].daxValue;

        this.midasPerformance.firstDate = this.backtestResults[0].snapshotTimestamp
        this.midasPerformance.lastDate = this.backtestResults[this.backtestResults.length - 1].snapshotTimestamp;
        this.midasPerformance.startValue = this.backtestResults[0].underDax;
        this.midasPerformance.endValue = this.backtestResults[this.backtestResults.length - 1].underDax;
      }
      let daxMin = 1000000000;
      let daxMax = 0;
      let midasMin = 1000000000;
      let midasMax = 0;
      for (let i = 0; i < this.backtestResults.length; i++) {
        daxMin = Math.min(daxMin, this.backtestResults[i].daxValue);
        daxMax = Math.max(daxMax, this.backtestResults[i].daxValue);
        midasMin = Math.min(midasMin, this.backtestResults[i].underDax);
        midasMax = Math.max(midasMax, this.backtestResults[i].underDax);
      }
      this.daxPerformance.lowestValue = daxMin;
      this.daxPerformance.highestValue = daxMax;
      this.midasPerformance.lowestValue = midasMin;
      this.midasPerformance.highestValue = midasMax;
      this.daxPerformance.performanceMin = (((this.daxPerformance.lowestValue / this.daxPerformance.startValue) - 1) * 100);
      this.daxPerformance.performanceMax = (((this.daxPerformance.highestValue / this.daxPerformance.startValue) - 1) * 100);
      this.daxPerformance.performanceFinal = (((this.daxPerformance.endValue / this.daxPerformance.startValue) - 1) * 100);
      this.midasPerformance.performanceMin = (((this.midasPerformance.lowestValue / this.midasPerformance.startValue) - 1) * 100);
      this.midasPerformance.performanceMax = (((this.midasPerformance.highestValue / this.midasPerformance.startValue) - 1) * 100);
      this.midasPerformance.performanceFinal = (((this.midasPerformance.endValue / this.midasPerformance.startValue) - 1) * 100);
    }
  }

}
