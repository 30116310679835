<!-- CHECK FOR NEEDED PERMISSIONS -->
<div class="container mt-4" *ngIf="!permitted">
  <ngb-alert type="danger" [dismissible]="false" class="mb-4" style="text-align: center;">
    <span class="mt-3" [innerHTML]="'NOT_ALLOWED' | translate | keepHtml"></span>
  </ngb-alert>
</div>

<div class="center-content mt-5 mb-5" *ngIf="permitted">

  <h4 class="container pb-5">Work in progress!</h4>

  <div class="container pb-5">
    <a href="https://www.lemon.markets/de-de">lemon.markets</a>
  </div>

  <h6 class="container">lemon.markets account summary:</h6>

</div>

<div class="container" *ngIf="permitted">
  <pre>{{ tradeSummaryResult | json }}</pre>
</div>
