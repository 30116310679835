<form class="form-inline">
  <mat-form-field appearance="fill">
    <mat-label>Preset</mat-label>
    <mat-select id="presetSelectDropdown" [(ngModel)]="selectedPreset" name="presetSelection" (change)="changePreset($event);">
      <mat-option *ngFor="let template of provider.availablePresets" [disabled]="template=='PRESET_USER_DEFINED'" [value]="template" (click)="changePreset($event);" [selected]="template==selectedPreset">{{ template | translate }}</mat-option>
    </mat-select>
  </mat-form-field> <!-- [disabled]="template=='PRESET_USER_DEFINED'" -->
</form>

<form [formGroup]="thresholdsLeverages">
  <table class="nowrap">
    <tr>
      <td>{{ 'SIGNAL' | translate }}</td>
      <td class="pl-3">{{ 'THRESHOLD' | translate }}</td>
      <td class="pl-4">{{ 'LEVER' | translate }}</td>
    </tr>

    <tr>
      <td class="darkgreen pl-2 pr-2">{{ 'DARKGREEN' | translate }}</td>
      <td class="pl-3"><span class="mathsymbol">&gt;</span>
        <mat-form-field class="value-form small-custom" appearance="fill">
          <input matInput type="text" name="signalToDarkGreenMidasThreshold" formControlName="signalToDarkGreenMidasThreshold" (keypress)="numericOnly($event)" (change)="changeValues($event);">
        </mat-form-field>
      </td>
      <td class="pl-4"><mat-form-field class="value-form small-custom" appearance="fill">
        <input matInput type="text" name="leverageWhenSignalIsDarkGreen" formControlName="leverageWhenSignalIsDarkGreen" (keypress)="numericOnly($event)" (change)="changeValues($event);">
      </mat-form-field>
      </td>
    </tr>

    <tr>
      <td class="green pl-2 pr-2">{{ 'GREEN' | translate }}</td>
      <td class="pl-3"><span class="mathsymbol">&gt;</span>
        <mat-form-field class="value-form small-custom" appearance="fill">
          <input matInput type="text" name="signalToGreenMidasThreshold" formControlName="signalToGreenMidasThreshold" (keypress)="numericOnly($event)" (change)="changeValues($event);">
        </mat-form-field>
      </td>
      <td class="pl-4"><mat-form-field class="value-form small-custom" appearance="fill">
        <input matInput type="text" name="leverageWhenSignalIsGreen" formControlName="leverageWhenSignalIsGreen" (keypress)="numericOnly($event)" (change)="changeValues($event);">
      </mat-form-field>
      </td>
    </tr>

    <tr>
      <td class="lightgreen pl-2 pr-2">{{ 'LIGHTGREEN' | translate }}</td>
      <td class="pl-3"><span class="mathsymbol">&gt;</span>
        <mat-form-field class="value-form small-custom" appearance="fill">
          <input matInput type="text" name="signalToLightGreenMidasThreshold" formControlName="signalToLightGreenMidasThreshold" (keypress)="numericOnly($event)" (change)="changeValues($event);">
        </mat-form-field>
      </td>
      <td class="pl-4"><mat-form-field class="value-form small-custom" appearance="fill">
        <input matInput type="text" name="leverageWhenSignalIsLightGreen" formControlName="leverageWhenSignalIsLightGreen" (keypress)="numericOnly($event)" (change)="changeValues($event);">
      </mat-form-field>
      </td>
    </tr>

    <tr>
      <td class="darkyellow pl-2 pr-2">{{ 'DARKYELLOW' | translate }}</td>
      <td class="pl-3"><span class="mathsymbol">&gt;</span>
        <mat-form-field class="value-form small-custom" appearance="fill">
          <input matInput type="text" name="signalToDarkYellowMidasThreshold" formControlName="signalToDarkYellowMidasThreshold" (keypress)="numericOnly($event)" (change)="changeValues($event);">
        </mat-form-field>
      </td>
      <td class="pl-4"><mat-form-field class="value-form small-custom" appearance="fill">
        <input matInput type="text" name="leverageWhenSignalIsDarkYellow" formControlName="leverageWhenSignalIsDarkYellow" (keypress)="numericOnly($event)" (change)="changeValues($event);">
      </mat-form-field>
      </td>
    </tr>

    <tr>
      <td class="yellow pl-2 pr-2">{{ 'YELLOW' | translate }}</td>
      <td class="pl-3"><span class="mathsymbol">&gt;</span>
        <mat-form-field class="value-form small-custom" appearance="fill">
          <input matInput type="text" name="signalToYellowMidasThreshold" formControlName="signalToYellowMidasThreshold" (keypress)="numericOnly($event)" (change)="changeValues($event);">
        </mat-form-field>
      </td>
      <td class="pl-4"><mat-form-field class="value-form small-custom" appearance="fill">
        <input matInput type="text" name="leverageWhenSignalIsYellow" formControlName="leverageWhenSignalIsYellow" (keypress)="numericOnly($event)" (change)="changeValues($event);">
      </mat-form-field>
      </td>
    </tr>

    <tr>
      <td class="lightyellow pl-2 pr-2">{{ 'LIGHTYELLOW' | translate }}</td>
      <td class="pl-3"><span class="mathsymbol">&gt;</span>
        <mat-form-field class="value-form small-custom" appearance="fill">
          <input matInput type="text" name="signalToLightYellowMidasThreshold" formControlName="signalToLightYellowMidasThreshold" (keypress)="numericOnly($event)" (change)="changeValues($event);">
        </mat-form-field>
      </td>
      <td class="pl-4"><mat-form-field class="value-form small-custom" appearance="fill">
        <input matInput type="text" name="leverageWhenSignalIsLightYellow" formControlName="leverageWhenSignalIsLightYellow" (keypress)="numericOnly($event)" (change)="changeValues($event);">
      </mat-form-field>
      </td>
    </tr>

    <tr>
      <td class="lightred pl-2 pr-2">{{ 'LIGHTRED' | translate }}</td>
      <td class="pl-3"><span class="mathsymbol">&gt;</span>
        <mat-form-field class="value-form small-custom" appearance="fill">
          <input matInput type="text" name="signalToLightRedMidasThreshold" formControlName="signalToLightRedMidasThreshold" (keypress)="numericOnly($event)" (change)="changeValues($event);">
        </mat-form-field>
      </td>
      <td class="pl-4"><mat-form-field class="value-form small-custom" appearance="fill">
        <input matInput type="text" name="leverageWhenSignalIsLightRed" formControlName="leverageWhenSignalIsLightRed" (keypress)="numericOnly($event)" (change)="changeValues($event);">
      </mat-form-field>
      </td>
    </tr>

    <tr>
      <td class="red pl-2 pr-2">{{ 'RED' | translate }}</td>
      <td class="pl-3"><span class="mathsymbol">&gt;</span>
        <mat-form-field class="value-form small-custom" appearance="fill">
          <input matInput type="text" name="signalToRedMidasThreshold" formControlName="signalToRedMidasThreshold" (keypress)="numericOnly($event)" (change)="changeValues($event);">
        </mat-form-field>
      </td>
      <td class="pl-4"><mat-form-field class="value-form small-custom" appearance="fill">
        <input matInput type="text" name="leverageWhenSignalIsRed" formControlName="leverageWhenSignalIsRed" (keypress)="numericOnly($event)" (change)="changeValues($event);">
      </mat-form-field>
      </td>
    </tr>

    <tr>
      <td class="darkred pl-2 pr-2">{{ 'DARKRED' | translate }}</td>
      <td class="pl-3"><span class="lastline">&le;&nbsp;&nbsp;&nbsp;&nbsp;{{ thresholds.signalToRedMidasThreshold }}</span></td>
      <td class="pl-4"><mat-form-field class="value-form small-custom" appearance="fill">
        <input matInput type="text" name="leverageWhenSignalIsDarkRed" formControlName="leverageWhenSignalIsDarkRed" (keypress)="numericOnly($event)" (change)="changeValues($event);">
      </mat-form-field>
      </td>
    </tr>

  </table>
</form>
