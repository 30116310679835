import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [
    './app.component.scss',
    './shared/styles/tradstrat.scss',
    './shared/styles/globalColours.scss'
  ]
})
export class AppComponent {
  title = 'tradstrat-frontend';
}
