import { MidasOptions } from '../tradstrat-api/model/request/MidasOptions';
import { UserPermissionsService } from './../auth/permissions.service';
import { MidasResult } from '../tradstrat-api/model/MidasResult';
import { SpinnerService } from '../shared/components/spinner/spinner.service';
import { TradStratApiService } from '../tradstrat-api/api.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { saveAs } from 'file-saver';

// tslint:disable: max-line-length tslint:disable: no-unused-expression

@Component({
  selector: 'app-midas',
  templateUrl: './midas.component.html',
  styleUrls: [
    './midas.component.scss',
    './../shared/styles/tradstrat.scss',
    './../shared/styles/globalColours.scss'
  ]
})
export class MidasComponent implements OnInit {

  @ViewChild(DatatableComponent) table: DatatableComponent;

  private apiService: TradStratApiService;
  private spinnerService: SpinnerService;
  private permissionServive: UserPermissionsService;

  latestMidasResult: MidasResult;
  selectedMidasResult: MidasResult;
  midasResults: MidasResult[];
  filteredMidasResults: MidasResult[];

  spinners: number;
  selected: any[] = [];
  schedulerIds: Set<number> = new Set();
  selectedSchedulersMap: Map<number, boolean> = new Map<number, boolean>();

  constructor(public authService: AuthService, userPermissionsService: UserPermissionsService, tradStratApiService: TradStratApiService, spinnerService: SpinnerService) {
    this.singleSelectCheck = this.singleSelectCheck.bind(this); // Workaround: Row selection/deselection
    this.apiService = tradStratApiService;
    this.spinnerService = spinnerService;
    this.permissionServive = userPermissionsService;
    this.apiService.latestMidasResult$.subscribe((result: MidasResult) => {
      this.latestMidasResult = result;
      this.reduceSpinner();
    });
    this.apiService.midasResults$.subscribe((result: MidasResult[]) => {
      this.midasResults = result;
      this.getSchedulerIds(result);
      this.filteredMidasResults = clone(this.midasResults);

      this.reduceSpinner();
    });
  }

  ngOnInit(): void {
    this.permissionServive.allowCalculateMidas$.subscribe((allowed: boolean) => {
      if (allowed) { // Granted permission, read database and calculate midas
        // console.log('Granted permission, read database and calculate midas'); // DEBUG
        this.initContent();
      }
    });
  }

  private initContent(): void {
    let midasOptions : MidasOptions = new MidasOptions;
    midasOptions.schedulerPattern = "1|0,2,4|3"; // TODO
    this.spinners = 2;
    this.spinnerService.startSpin();
    this.apiService.getLatestMidas(midasOptions);
    this.apiService.getMidas(midasOptions);
  }

  startSorting(sort: () => any): any {
    return sort();
  }

  private reduceSpinner(): void {
    this.spinners -= 1;
    if (this.spinners <= 0) {
      this.spinners = 0;
      this.spinnerService.stopSpin();
    }
  }

  onTableRowClick(event: any): void {
    if (event.type === 'click' ) {
      // console.log(JSON.stringify(event.row)); // DEBUG
      if (this.selected.length > 0) {
        this.selectedMidasResult = this.selected[0];
      } else {
        this.selectedMidasResult = null;
      }
    }
  }

  singleSelectCheck(row: any): boolean {
    return this.selected.indexOf(row) === -1;
  }

  getSchedulerIds(midasResults: any): void {
    if (!midasResults) {
      return;
    }
    for (const midasResult of midasResults) {
      if (midasResult.scheduled) {
        this.schedulerIds.add(midasResult.schedulerId);
        // this.selectedSchedulers.set(midasResult.schedulerId, true);
        this.selectedSchedulersMap[midasResult.schedulerId] = true;
      }
    }
  }

  filterScheduler(): void {
    const selected: number[] = [];
    for (const schedulerId of this.schedulerIds) {
      this.selectedSchedulersMap[schedulerId] === true ? selected.push(schedulerId) : null;
    }

    // TODO: write to local storage/cookie storage

    const tempFilter = this.midasResults.filter((result: MidasResult) => {
      return selected.includes(result.schedulerId);
    });

    // Update table rowsrows
    this.filteredMidasResults = tempFilter;

    // Whenever the filter changes, always go back to the first page and deselect rows
    this.table.offset = 0;
    this.selectedMidasResult = null;
  }

  exportToCsv(): void {
    let csvContent = 'scheduled;schedulerID;snapshotTimestamp;midasResult;leverString;leverValue\n';
    for (const midasResult of this.filteredMidasResults) {
      csvContent += midasResult.scheduled + ';'
                 + midasResult.schedulerId + ';'
                 + midasResult.snapshotTimestamp + ';'
                 + midasResult.result.toString().replace('.', ',') + ';'
                 + '"' + midasResult.leverString.replace(new RegExp('"', 'g'), '\'') + '";'
                 + midasResult.leverValue.toString().replace('.', ',') + ';'
                 + midasResult.daxCurrent.toString().replace('.', ',')
                 + '\n';
    }
    const filename = 'results.csv';
    const blob = new Blob([csvContent], { type: 'text/csv' });
    saveAs(blob, filename);
  }
}

function clone(obj: any): any {
  let copy: any;
  if (null == obj || 'object' !== typeof obj) { // Handle the 3 simple types, and null or undefined
    return obj;
  }
  if (obj instanceof Date) { // Handle Date
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
  }
  if (obj instanceof Array) { // Handle Array
      copy = [];
      for (let i = 0, len = obj.length; i < len; i++) {
          copy[i] = clone(obj[i]);
      }
      return copy;
  }
  if (obj instanceof Object) { // Handle Object
      copy = {};
      for (const attr in obj) {
          if (obj.hasOwnProperty(attr)) {
            copy[attr] = clone(obj[attr]);
          }
      }
      return copy;
  }
  throw new Error('Unable to copy Object! Its type is not supported.');
}
