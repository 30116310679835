import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'performance', pure: false })
export class PerformanceValuePipe implements PipeTransform {

  constructor() {
  }

  public transform(content: number): string {
    if (content === null) {
      return ""
    }
    if (content === 0) {
      return "±" + Math.abs(content).toFixed(2) + "%"
    }
    if (content > 0) {
      return "+" + content.toFixed(2) + "%"
    }
    if (content < 0) {
      return "−" + Math.abs(content).toFixed(2) + "%"
    }
  }

}
