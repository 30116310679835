<div>
  <mat-radio-group [(ngModel)]="rangeModel" aria-label="Select an option" (ngModelChange)="this.setSelectedDate();">
    <mat-radio-button class="mr-3 ml-0" value="YTD">{{ 'SIMULATION_YTD' | translate }}</mat-radio-button>
    <mat-radio-button class="mr-3 ml-0" value="YEAR">{{ 'SIMULATION_CUSTOM_YEAR' | translate }}</mat-radio-button>
    <mat-radio-button class="mr-3 ml-0" value="CUSTOM">{{ 'SIMULATION_CUSTOM_PERIOD' | translate }}</mat-radio-button>
  </mat-radio-group>
</div>

<!-- YEAR TO DATE (ACTUAL YEAR) -->
<ng-container *ngIf="rangeModel=='YTD'">
  <div class="mr-3 ml-0">
    {{ 'SIMULATION_YTD' | translate }}: <strong>{{this.actualYear}}</strong>
  </div>
</ng-container>

<!-- CUSTOM YEAR -->
<ng-container *ngIf="rangeModel=='YEAR'">
  <form class="form-inline">
    <div class="form-group mr-3 ml-0">
      <!-- <label for="yearSelection">Please select year:</label> -->
      <select class="form-control" name="yearSelection" [(ngModel)]="customYear" (change)="this.setSelectedDate();">
        <option *ngFor="let year of this.availableYears" [value]=year [selected]="year==customYear">{{year}}</option>
      </select>
    </div>
  </form>
</ng-container>

<!-- CUSTOM PERIOD: DATE FROM / DATE TO -->
<ng-container *ngIf="rangeModel=='CUSTOM'">
  <mat-form-field class="datepicker-form-field" appearance="fill">
    <mat-label [translate]="'SIMULATION_SELECT_RANGE'"></mat-label>
    <mat-date-range-input [formGroup]="dateRange" [rangePicker]="dateRangePicker">
      <input matStartDate placeholder="Start date" formControlName="start" (dateChange)="this.setSelectedDate();">
      <input matEndDate placeholder="End date" formControlName="end" (dateChange)="this.setSelectedDate();">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="dateRangePicker"></mat-datepicker-toggle>
    <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
  </mat-form-field>
</ng-container>
