import { Injectable, EventEmitter } from '@angular/core';
// import { SpinnerContent } from './SpinnerContent';

@Injectable({ providedIn: 'root' })
export class SpinnerService {

  public visibilityChanged: EventEmitter<boolean> = new EventEmitter();
  public marginTopChanged: EventEmitter<number> = new EventEmitter();
  // public contentChanged: EventEmitter<SpinnerContent> = new EventEmitter();
  // public cancelClicked: EventEmitter<any> = new EventEmitter();

  constructor() { }

  /*
  setContent(content: SpinnerContent): void {
    this.contentChanged.emit(content);
  }

  spinContent(content: SpinnerContent): void {
    this.contentChanged.emit(content);
    this.visibilityChanged.emit(true);
  }
  */

  startSpin(): void {
    this.visibilityChanged.emit(true);
  }

  stopSpin(): void {
    this.visibilityChanged.emit(false);
  }

  setMarginTop(margin: number): void {
    this.marginTopChanged.emit(margin);
  }

}
