<div *ngIf="chartsInitialized">
  <apx-chart id="dax-midas"
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [colors]="chartOptions.colors"
    [xaxis]="chartOptions.xaxis"
    [yaxis]="chartOptions.yaxis"
    [stroke]="chartOptions.stroke"
    [dataLabels]="commonOptions.dataLabels"
    [markers]="commonOptions.markers"
    [grid]="commonOptions.grid"
    [tooltip]="commonOptions.tooltip"
    [legend]="legend"
    [animation]="animation"
  ></apx-chart>
</div>
