<div class="d-flex flex-wrap limit-height">
  <div class="full-width">{{ 'daxChange5DaysIndicatorWeight' | translate }}</div>
  <div class="flex full-width">
    <mat-slider id="1" thumbLabel="false" tickInterval="1" min="0" max="3.0" step="0.5" value="1.0" [(ngModel)]="daxChange5DaysIndicatorWeight" (input)="onSliderChange($event);" (change)="onSliderChange($event);"></mat-slider>
  </div>
  <div class="small-width"><b>{{ weights.daxChange5DaysIndicatorWeight.toFixed(1) }}</b></div>
</div>

<div class="d-flex flex-wrap limit-height">
  <div class="full-width">{{ 'daxGdCompareDaxCurrentIndicatorWeight' | translate }}</div>
  <div class="flex full-width">
    <mat-slider id="2" thumbLabel="false" tickInterval="1" min="0" max="3.0" step="0.5" value="1.0" [(ngModel)]="daxGdCompareDaxCurrentIndicatorWeight" (input)="onSliderChange($event);" (change)="onSliderChange($event);"></mat-slider>
  </div>
  <div class="small-width"><b>{{ weights.daxGdCompareDaxCurrentIndicatorWeight.toFixed(1) }}</b></div>
</div>

<div class="d-flex flex-wrap limit-height">
  <div class="full-width">{{ 'daxGd5Change5DaysIndicatorWeight' | translate }}</div>
  <div class="flex full-width">
    <mat-slider id="3" thumbLabel="false" tickInterval="1" min="0" max="3.0" step="0.5" value="1.0" [(ngModel)]="daxGd5Change5DaysIndicatorWeight" (input)="onSliderChange($event);" (change)="onSliderChange($event);"></mat-slider>
  </div>
  <div class="small-width"><b>{{ weights.daxGd5Change5DaysIndicatorWeight.toFixed(1) }}</b></div>
</div>

<div class="d-flex flex-wrap limit-height">
  <div class="full-width">{{ 'daxGd30CompareIndicatorWeight' | translate }}</div>
  <div class="flex full-width">
    <mat-slider id="4" thumbLabel="false" tickInterval="1" min="0" max="3.0" step="0.5" value="1.0" [(ngModel)]="daxGd30CompareIndicatorWeight" (input)="onSliderChange($event);" (change)="onSliderChange($event);"></mat-slider>
  </div>
  <div class="small-width"><b>{{ weights.daxGd30CompareIndicatorWeight.toFixed(1) }}</b></div>
</div>

<div class="d-flex flex-wrap limit-height">
  <div class="full-width">{{ 'daxGd100CompareIndicatorWeight' | translate }}</div>
  <div class="flex full-width">
    <mat-slider id="5" thumbLabel="false" tickInterval="1" min="0" max="3.0" step="0.5" value="1.0" [(ngModel)]="daxGd100CompareIndicatorWeight" (input)="onSliderChange($event);" (change)="onSliderChange($event);"></mat-slider>
  </div>
  <div class="small-width"><b>{{ weights.daxGd100CompareIndicatorWeight.toFixed(1) }}</b></div>
</div>

<div class="d-flex flex-wrap limit-height">
  <div class="full-width">{{ 'daxGd200CompareIndicatorWeight' | translate }}</div>
  <div class="flex full-width">
    <mat-slider id="6" thumbLabel="false" tickInterval="1" min="0" max="3.0" step="0.5" value="1.0" [(ngModel)]="daxGd200CompareIndicatorWeight" (input)="onSliderChange($event);" (change)="onSliderChange($event);"></mat-slider>
  </div>
  <div class="small-width"><b>{{ weights.daxGd200CompareIndicatorWeight.toFixed(1) }}</b></div>
</div>

<div class="d-flex flex-wrap limit-height">
  <div class="full-width">{{ 'vdaxNewIndicatorWeight' | translate }}</div>
  <div class="flex full-width">
    <mat-slider id="7" thumbLabel="false" tickInterval="1" min="0" max="3.0" step="0.5" value="1.0" [(ngModel)]="vdaxNewIndicatorWeight" (input)="onSliderChange($event);" (change)="onSliderChange($event);"></mat-slider>
  </div>
  <div class="small-width"><b>{{ weights.vdaxNewIndicatorWeight.toFixed(1) }}</b></div>
</div>

<div class="d-flex flex-wrap limit-height">
  <div class="full-width">{{ 'eurUsdChange5DaysIndicatorWeight' | translate }}</div>
  <div class="flex full-width">
    <mat-slider id="8" thumbLabel="false" tickInterval="1" min="0" max="3.0" step="0.5" value="1.0" [(ngModel)]="eurUsdChange5DaysIndicatorWeight" (input)="onSliderChange($event);" (change)="onSliderChange($event);"></mat-slider>
  </div>
  <div class="small-width"><b>{{ weights.eurUsdChange5DaysIndicatorWeight.toFixed(1) }}</b></div>
</div>

<div class="d-flex flex-wrap limit-height">
  <div class="full-width">{{ 'xauChange5DaysIndicatorWeight' | translate }}</div>
  <div class="flex full-width">
    <mat-slider id="9" thumbLabel="false" tickInterval="1" min="0" max="3.0" step="0.5" value="1.0" [(ngModel)]="xauChange5DaysIndicatorWeight" (input)="onSliderChange($event);" (change)="onSliderChange($event);"></mat-slider>
  </div>
  <div class="small-width"><b>{{ weights.xauChange5DaysIndicatorWeight.toFixed(1) }}</b></div>
</div>

<div class="d-flex flex-wrap limit-height">
  <div class="full-width">{{ 'vixIndicatorWeight' | translate }}</div>
  <div class="flex full-width">
    <mat-slider id="10" thumbLabel="false" tickInterval="1" min="0" max="3.0" step="0.5" value="1.0" [(ngModel)]="vixIndicatorWeight" (input)="onSliderChange($event);" (change)="onSliderChange($event);"></mat-slider>
  </div>
  <div class="small-width"><b>{{ weights.vixIndicatorWeight.toFixed(1) }}</b></div>
</div>

<div class="d-flex flex-wrap limit-height">
  <div class="full-width">{{ 'usBondsIndicatorWeight' | translate }}</div>
  <div class="flex full-width">
    <mat-slider id="11" thumbLabel="false" tickInterval="1" min="0" max="3.0" step="0.5" value="1.0" [(ngModel)]="usBondsIndicatorWeight" (input)="onSliderChange($event);" (change)="onSliderChange($event);"></mat-slider>
  </div>
  <div class="small-width"><b>{{ weights.usBondsIndicatorWeight.toFixed(1) }}</b></div>
</div>

